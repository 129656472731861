import { useState } from 'react'
import styled from 'styled-components/macro'

import { Divider } from '@mui/material'
import dayjs from 'dayjs'
import { useSetRecoilState } from 'recoil'

import ReportEditCard from './ReportEditCard'
import Flex from 'src/atomics/Flex'
import Icon from 'src/atomics/Icon'
import useModal from 'src/hooks/useModal'
import { useDeleteReport } from 'src/react-query-hooks/report'
import { isOnEditAtom } from 'src/stores/commonStores'
import CardStyle from 'src/styles/CardStyle'

export default function ReportCard({
  index,
  onClick,
  report,
  showIcon = false,
  isDrag = false,
  isSelected = false,
}: {
  index: number
  onClick: () => void
  report: ReportInfo
  showIcon?: boolean
  isDrag?: boolean
  isSelected?: boolean
}) {
  const [isEdit, setIsEdit] = useState(false)
  const { mutate: deleteReport } = useDeleteReport({ id: report.id! })
  const { openModal, closeModal } = useModal()
  const setIsOnEdit = useSetRecoilState(isOnEditAtom)

  const { id, date: targetDate, place, image } = report

  const handleClickDelete = () => {
    deleteReport()
  }

  const DEFAULT_IMAGE =
    'https://chapteronedogtraining.com.au/wp-content/uploads/2021/08/home-puppy-1-1000.jpg'
  const datesYYYYmmDD = dayjs(targetDate).format('YYYY.MM.DD')
  const datesHHmm = dayjs(targetDate).format('HH:mm')
  const today = dayjs()
  const diff = dayjs(targetDate).diff(today, 'd')

  return (
    <>
      {isEdit ? (
        <ReportEditCard
          reportInfo={report}
          onCancel={() => {
            setIsEdit(false)
            setIsOnEdit(false)
          }}
        />
      ) : (
        <ReportCardContainer align={'between'} onClick={onClick}>
          {isDrag && (
            <Flex align={'center'} onClick={() => console.log('map')}>
              <Icon type={'DRAG'} fontSize={'small'} color={'disabled'} />
            </Flex>
          )}
          <Flex
            onClick={(e) => {
              e.stopPropagation()
              openModal({
                body: (
                  <StyledImg
                    onClick={closeModal}
                    src={image || DEFAULT_IMAGE}
                    alt={'img'}
                  />
                ),
              })
            }}
          >
            <img src={image || DEFAULT_IMAGE} alt={'img'} className={'image'} />
          </Flex>
          <Flex
            direction={'column'}
            gap={2}
            align={'centerY'}
            style={{ flex: 1 }}
          >
            <Flex align={'centerY'}>
              <Flex
                className={`info ${isSelected ? 'selected' : ''}`}
                style={{ minWidth: 50 }}
              >
                No. {index + 1}
              </Flex>
              <Divider sx={{ m: 1 }} orientation="vertical" />
              <Flex className={'info'}>{datesYYYYmmDD}</Flex>
              <Divider sx={{ m: 1 }} orientation="vertical" />
              <Flex className={'info'}>{datesHHmm}</Flex>
            </Flex>
            <Flex>
              <Flex className={'info'}>{place.placeName}</Flex>
            </Flex>
            <Flex>
              <Flex className={'info address'}>{place.address}</Flex>
            </Flex>
          </Flex>
          {showIcon && (
            <Flex
              direction={'column'}
              align={'center'}
              gap={8}
              onClick={() => console.log('map')}
            >
              <div>
                <Icon type={'FIND_LOCATION'} color={'action'} />
              </div>
              <Flex gap={8}>
                <div
                  onClick={() => {
                    setIsEdit(true)
                    setIsOnEdit(true)
                  }}
                >
                  <Icon type={'EDIT'} color={'info'} />
                </div>
                <div onClick={handleClickDelete}>
                  <Icon type={'DELETE'} color={'warning'} />
                </div>
              </Flex>
            </Flex>
          )}
        </ReportCardContainer>
      )}
    </>
  )
}

const ReportCardContainer = styled(CardStyle)`
  padding: 12px;
  border: 1px solid #f1f1f1;
  border-radius: 12px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
  cursor: pointer;
  gap: 12px;

  &:hover {
  }

  .info {
    font-size: 0.8rem;
    color: #333;

    &.address {
      color: #666;
    }
    &.selected {
      font-weight: 900;
      color: #6144dd;
    }
  }

  .bold {
    font-weight: 700;
  }

  .image {
    width: 80px;
    height: 80px;
    border-radius: 12px;
    object-fit: cover;
  }
`

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 12px;
`
