import { useSetRecoilState } from 'recoil'

import { ModalInfo, modalAtom } from 'src/stores/modalStores'

const useModal = () => {
  const setModalState = useSetRecoilState(modalAtom)

  const openModal = ({ ...rest }: Omit<ModalInfo, 'show'>) =>
    setModalState((p) => ({ ...p, ...rest, show: true }))
  const closeModal = () => setModalState((p) => ({ ...p, show: false }))

  return { openModal, closeModal }
}

export default useModal
