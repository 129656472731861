import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { DevTool } from '@hookform/devtools'
import { Button, InputAdornment } from '@mui/material'
import { useRecoilState } from 'recoil'

import { LostFormType } from './LostRegisterPage'
import Flex from 'src/atomics/Flex'
import Icon from 'src/atomics/Icon'
import Page from 'src/atomics/Page'
import DateTimePickers from 'src/components/DateTimePickers'
import Inputs from 'src/components/Inputs'
import Loading from 'src/components/Loading'
import PlaceDrawer from 'src/components/lost/PlaceDrawer'
import UploadButtons from 'src/components/UploadButtons'
import {
  useLostRegister,
  useLostRequestDetail,
} from 'src/react-query-hooks/lost'
import { selectedPlaceAtom } from 'src/stores/commonStores'
import GenderRadios from 'src/templates/GenderRadios'

export default function LostEditPage() {
  const navigate = useNavigate()
  const [isOnPlaceAdd, setIsOnPlaceAdd] = useState(false)
  const [selectedPlace, setSelectedPlace] = useRecoilState(selectedPlaceAtom)
  const { mutate: lostRegister } = useLostRegister({
    isEdit: true,
    onSuccess: () => {
      navigate(-1)
    },
  })
  const { id: lostRequestInfoID } = useParams()
  const { data: lostRequestDetail, isFetching } = useLostRequestDetail(
    lostRequestInfoID!
  )

  const reportList = lostRequestDetail.reportList?.items ?? []
  const { lostInfo, companionAnimalInfo } = lostRequestDetail
  const { lostDate, lostPlace, contact } = lostInfo
  const {
    address,
    placeName,
    location: { lat, lng },
  } = lostPlace
  const { name, weight, age, gender, memo, image } = companionAnimalInfo

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<LostFormType>()

  const onSubmit: SubmitHandler<LostFormType> = (values) => {
    if (selectedPlace) {
      const { x = '0', y = '0' } = selectedPlace
      const {
        date,
        lostPlace: { address, placeName },
        contact,
        name,
        weight,
        image,
        age,
        gender,
        memo,
      } = values
      const registerData: LostRequestInfo = {
        id: lostRequestInfoID,
        lostInfo: {
          lostDate: date,
          lostPlace: {
            address,
            placeName,
            location: { lat: Number(y), lng: Number(x) },
          },
          contact,
        },
        companionAnimalInfo: { name, weight, image, age, gender, memo },
      }
      console.log(registerData, lostRequestInfoID)
      lostRegister(registerData)
    }
  }

  useEffect(() => {
    setSelectedPlace({
      address_name: address,
      place_name: placeName,
      y: String(lat),
      x: String(lng),
    })
  }, [])

  if (isFetching) return <Loading />

  return (
    <LostEditPageContainer fullX={false}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit(onSubmit)()
        }}
        className={'form__container'}
      >
        <UploadButtons<LostFormType>
          id={'file'}
          value={image}
          fieldName={'image'}
          register={register}
          setValue={setValue}
        />
        <Inputs<LostFormType>
          label={'이름'}
          value={name}
          fieldName={'name'}
          register={register}
          // error={isFocus && validateMsg === 'name'}
        />
        <Flex gap={16}>
          <Inputs<LostFormType>
            label={'나이'}
            value={age}
            fieldName={'age'}
            textAlign={'center'}
            register={register}
          />
          <Inputs<LostFormType>
            label={'몸무게'}
            value={weight}
            fieldName={'weight'}
            textAlign={'center'}
            register={register}
            endAdornment={<InputAdornment position="end">kg</InputAdornment>}
          />
        </Flex>
        <GenderRadios<LostFormType>
          value={gender}
          register={register}
          fieldName={'gender'}
        />

        <DateTimePickers<LostFormType>
          fieldName={'date'}
          label={'실종시간'}
          value={lostDate}
          // error={validateMsg === 'date'}
          register={register}
        />
        <Inputs<LostFormType>
          label={'실종장소'}
          fieldName={'lostPlace.address'}
          value={address}
          register={register}
          onClick={() => setIsOnPlaceAdd(true)}
          // error={isFocus && validateMsg === 'name'}
        />
        <Inputs<LostFormType>
          showLabel={false}
          label={'상세주소'}
          value={placeName}
          fieldName={'lostPlace.placeName'}
          register={register}
          onClick={() => setIsOnPlaceAdd(true)}
          // error={isFocus && validateMsg === 'name'}
        />
        <PlaceDrawer
          open={isOnPlaceAdd}
          onAdd={(place) => {
            setValue('lostPlace.address', place.address_name || '')
            setValue('lostPlace.placeName', place.place_name)
          }}
          onClose={() => setIsOnPlaceAdd(false)}
        />
        <Inputs<LostFormType>
          label={'특이사항'}
          value={memo}
          multiline
          fieldName={'memo'}
          register={register}
        />
        <Inputs<LostFormType>
          label={'연락처'}
          value={contact}
          fieldName={'contact'}
          register={register}
        />

        <Button type={'submit'}>
          <Icon type={'CHECK'} fontSize={'small'} color={'success'} />
        </Button>
      </form>
      <DevTool control={control} />
    </LostEditPageContainer>
  )
}

const LostEditPageContainer = styled(Page)`
  .form__container {
    padding-top: 16px;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 16px;
  }
`
