import { Route, Routes } from 'react-router-dom'

import { useInjectKakaoMapApi } from 'react-kakao-maps-sdk'

import Wrapper from './atomics/Wrapper'
import Loading from './components/Loading'
import MainLayout from './layout/MainLayout'
import Info from './pages/Info'
import LostDetailPage from './pages/lost/LostDetailPage'
import LostEditPage from './pages/lost/LostEditPage'
import LostListPage from './pages/lost/LostListPage'
import LostRegisterPage from './pages/lost/LostRegisterPage'
import MoveDetailPage from './pages/move/MoveDetailPage'
import MoveEditPage from './pages/move/MoveEditPage'
import MoveListPage from './pages/move/MoveListPage'
import MoveRegisterPage from './pages/move/MoveRegisterPage'

function App() {
  const { loading } = useInjectKakaoMapApi({
    appkey: process.env.REACT_APP_KAKAO_MAP_KEY || '',
    libraries: ['services', 'clusterer'],
  })

  if (loading) {
    return <Loading />
  }
  return (
    <Wrapper direction={'column'}>
      <Routes>
        <Route element={<MainLayout />}>
          <Route index element={<MoveListPage />} />
          <Route path={'/lost'} element={<LostListPage />} />
          <Route path={'/lost/register'} element={<LostRegisterPage />} />
          <Route path={'/lost/:id'} element={<LostDetailPage />} />
          <Route path={'/lost/:id/edit'} element={<LostEditPage />} />
          <Route path={'/move/register'} element={<MoveRegisterPage />} />
          <Route path={'/move/:id'} element={<MoveDetailPage />} />
          <Route path={'/move/:id/edit'} element={<MoveEditPage />} />
          <Route path={'/info'} element={<Info />} />
        </Route>
      </Routes>
    </Wrapper>
  )
}

export default App
