import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { InputAdornment } from '@mui/material'
import Button from '@mui/material/Button'

import Flex from 'src/atomics/Flex'
import Page from 'src/atomics/Page'
import DateTimePickers from 'src/components/DateTimePickers'
import Inputs from 'src/components/Inputs'
import UploadButtons from 'src/components/UploadButtons'
import {
  useMoveRequest,
  useMoveRequestDetail,
} from 'src/react-query-hooks/move'
import AddressSelector from 'src/templates/AddressSelector'
import GenderRadios from 'src/templates/GenderRadios'
import RequestTypeRadios from 'src/templates/RequestTypeRadios'
import TransportationRadios from 'src/templates/TransportationRadios'
import TripTypeRadios from 'src/templates/TripTypeRadios'

export default function MoveEditPage() {
  console.log('MoveEditPage')
  const navigate = useNavigate()
  const [isFocus, setIsFocus] = useState(false)

  const { id: moveDetailId } = useParams()

  const { data: moveRequestDetail } = useMoveRequestDetail(moveDetailId!)
  const { moveInfo, companionAnimalInfo } = moveRequestDetail
  const { requestType, transportation, tripType, startPoint, endPoint, date } =
    moveInfo

  const { name, age, gender, breed, weight, image, memo } = companionAnimalInfo

  const getIsFulfillment = () => {
    const requiredInfo: { [key: string]: string } = {
      // name,
      // startPoint,
      // endPoint,
      // date,
    }
    const checkList = Object.entries(requiredInfo).map(([key, value]) => ({
      key,
      value,
    }))
    const { key, value } = checkList.find(
      ({ key, value }) => !Boolean(value)
    ) || { key: 'passed', value: true }
    return { isPassed: Boolean(value), validateMsg: key }
  }

  const { isPassed, validateMsg } = getIsFulfillment()

  const { mutate: moveRequestRegister } = useMoveRequest({
    isEdit: true,
    onSuccess: () => navigate(-1),
  })

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()
  const onSubmit = (data: any) => {
    const {
      age,
      breed,
      date,
      gender,
      image,
      memo,
      name,
      requestType,
      startPoint,
      endPoint,
      transportation,
      tripType,
      weight,
    } = data
    const requestData: MoveRequestInfo = {
      moveInfo: {
        requestType,
        transportation,
        tripType,
        startPoint,
        endPoint,
        date,
      },
      companionAnimalInfo: { name, age, gender, breed, weight, memo, image },
      id: moveDetailId,
    }
    moveRequestRegister(requestData)
  }

  return (
    <MoveEditPageContainer fullX={false}>
      <Flex direction={'column'} gap={24} style={{ padding: `16px 0px` }}>
        <form
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex direction={'column'} gap={24} style={{ padding: `16px 0px` }}>
            <UploadButtons
              id={'file'}
              value={image}
              fieldName={'image'}
              register={register}
              setValue={setValue}
            />
            <Inputs
              label={'이름'}
              value={name}
              fieldName={'name'}
              register={register}
              error={isFocus && validateMsg === 'name'}
            />
            <Flex gap={24}>
              <Inputs
                label={'나이'}
                value={age}
                fieldName={'age'}
                textAlign={'center'}
                register={register}
              />
              <Inputs
                label={'몸무게'}
                value={weight}
                fieldName={'weight'}
                textAlign={'center'}
                register={register}
                endAdornment={
                  <InputAdornment position="end">kg</InputAdornment>
                }
              />
            </Flex>

            <GenderRadios register={register} fieldName={'gender'} />

            <Flex gap={24}>
              <Inputs
                id={'breed'}
                value={breed}
                label={'축종'}
                fieldName={'breed'}
                register={register}
              />
            </Flex>
            <RequestTypeRadios
              value={requestType}
              register={register}
              fieldName={'requestType'}
            />

            <TripTypeRadios
              value={tripType}
              register={register}
              fieldName={'tripType'}
            />

            <TransportationRadios
              value={transportation}
              register={register}
              fieldName={'transportation'}
            />
            <Flex gap={6} flex>
              <AddressSelector
                onChange={(address) => setValue('startPoint', address)}
              >
                <Inputs
                  fieldName={'startPoint'}
                  value={startPoint}
                  label={'출발지'}
                  error={validateMsg === 'startPoint'}
                  textAlign={'center'}
                  register={register}
                />
              </AddressSelector>
              <AddressSelector
                onChange={(address) => setValue('endPoint', address)}
              >
                <Inputs
                  fieldName={'endPoint'}
                  value={endPoint}
                  label={'목적지'}
                  error={validateMsg === 'endPoint'}
                  textAlign={'center'}
                  register={register}
                />
              </AddressSelector>
            </Flex>
            <DateTimePickers
              value={date}
              fieldName={'date'}
              label={'시간'}
              error={validateMsg === 'date'}
              register={register}
            />
            <Inputs
              value={memo}
              fieldName={'memo'}
              label={'특이사항'}
              multiline
              rows={4}
              register={register}
            />
          </Flex>
          <Button
            type={'submit'}
            variant="contained"
            style={{ borderRadius: 12 }}
            color={'secondary'}
            // disabled={!isPassed}
          >
            {'등록하기'}
          </Button>
        </form>
      </Flex>
    </MoveEditPageContainer>
  )
}

const MoveEditPageContainer = styled(Page)`
  .form__container {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 8px;
  }
`
