import styled from 'styled-components/macro'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Divider } from '@mui/material'
import dayjs from 'dayjs'

import Flex from 'src/atomics/Flex'
import Icon from 'src/atomics/Icon'
import CardStyle from 'src/styles/CardStyle'

export default function LostDetailCard({
  lostRequestDetail,
  onClick,
}: {
  lostRequestDetail: LostRequestInfo
  onClick: () => void
}) {
  const DEFAULT_IMAGE =
    'https://chapteronedogtraining.com.au/wp-content/uploads/2021/08/home-puppy-1-1000.jpg'

  const { lostInfo, companionAnimalInfo } = lostRequestDetail
  const { name, age, weight, gender, memo, image } = companionAnimalInfo
  const { lostDate, lostPlace, contact } = lostInfo
  const { address, placeName, location } = lostPlace

  const datesYYYYmmDD = dayjs(lostDate).format('YYYY.MM.DD')
  const datesHHmm = dayjs(lostDate).format('HH:mm')
  const today = dayjs()
  const diff = dayjs(lostDate).diff(today, 'd')
  return (
    <LostDetailCardContainer
      direction={'column'}
      align={'between'}
      onClick={onClick}
    >
      <Flex style={{ padding: '0px 16px' }}>
        <img src={image || DEFAULT_IMAGE} alt={'img'} className={'image'} />
      </Flex>
      <Flex
        direction={'column'}
        gap={4}
        style={{ background: 'red', padding: 16, color: '#fff' }}
      >
        <Flex gap={4} align={'between'}>
          <Flex className={'info bold'}>이름: {name}</Flex>
          <Divider sx={{ height: 12, m: 0.5 }} orientation="vertical" />
          <Flex className={'info'}>{age || 1}세</Flex>
          <Divider sx={{ height: 12, m: 0.5 }} orientation="vertical" />
          <Flex className={'info'}>{weight || 1}kg</Flex>
          <Divider sx={{ height: 12, m: 0.5 }} orientation="vertical" />
          <Flex className={'info'}>
            <Icon category={'gender'} type={gender || 'MALE'} />
          </Flex>
        </Flex>
        <Divider sx={{ m: 1 }} />
        <Flex>
          <Flex className={'info'}>{datesYYYYmmDD}</Flex>
          <Divider sx={{ height: 12, m: 1 }} orientation="vertical" />
          <Flex className={'info'}>{datesHHmm}</Flex>
        </Flex>
        <Divider sx={{ m: 1 }} />
        <Flex>실종장소: {address}</Flex>
        <Flex style={{ marginLeft: 70 }}>{placeName}</Flex>
        <Divider sx={{ m: 1 }} />
        <Flex style={{ whiteSpace: 'pre-wrap' }}>{`특이사항: ${
          memo || ''
        }`}</Flex>
        <Divider sx={{ m: 1 }} />
        <Flex>연락처: {contact}</Flex>
      </Flex>
    </LostDetailCardContainer>
  )
}

const LostDetailCardContainer = styled(CardStyle)`
  border-radius: 12px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
  cursor: pointer;
  gap: 16px;

  &:hover {
  }

  .info {
    font-size: 1.2rem;
  }

  .bold {
    font-weight: 700;
  }

  .image {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }
`
