import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import RoomIcon from '@mui/icons-material/Room'
import SortIcon from '@mui/icons-material/Sort'

import Flex from '../atomics/Flex'
import useGeoLocation from '../hooks/useGeoLocation'

export default function Location() {
  const geocoder = new window.kakao.maps.services.Geocoder()
  const { pos, error } = useGeoLocation()
  const { lat, lng } = pos
  const [address, setAddress] = useState('')

  useEffect(() => {
    geocoder.coord2RegionCode(lng, lat, (result, status) => {
      if (status === window.kakao.maps.services.Status.OK) {
        setAddress(result[0].address_name)
      }
    })
  }, [lat, lng])

  return (
    <LocationContainer align={'between'}>
      <Flex className={'location_box'} gap={4}>
        <Flex className={'icon'} align={'centerY'}>
          <RoomIcon fontSize={'small'} style={{ color: '#666' }} />
        </Flex>
        <Flex className={'address'} align={'centerY'}>
          {address}
        </Flex>
      </Flex>
      <div>
        <SortIcon fontSize={'small'} onClick={() => console.log('filter')} />
      </div>
    </LocationContainer>
  )
}

const LocationContainer = styled(Flex)`
  background: #fafafa;
  padding: 12px 16px;

  .location_box {
    .icon {
    }
    .address {
      font-size: 12px;
      color: #666;
    }
  }
`
