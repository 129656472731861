import { FC } from 'react'
import styled, { CSSObject } from 'styled-components/macro'

import { CustomOverlayMap } from 'react-kakao-maps-sdk'

interface ICustomOverlay {
  pos: PosType
  isSelected?: boolean
  children?: React.ReactNode
  onClick?: () => void
  style?: CSSObject
}

const CustomOverlay: FC<ICustomOverlay> = ({
  pos,
  isSelected = false,
  style = {},
  onClick,
  children,
  ...rest
}) => {
  const { lat, lng } = pos

  return (
    <CustomOverlayMap position={{ lat, lng }} clickable {...rest}>
      <StyledMarker
        style={style}
        onClick={onClick}
        className={`${isSelected ? 'selected' : ''}`}
      >
        {children}
      </StyledMarker>
    </CustomOverlayMap>
  )
}

export default CustomOverlay

const StyledMarker = styled.div<{ style: CSSObject }>`
  width: 30px;
  height: 30px;
  text-align: center;
  font-weight: 900;
  border: 1px solid #00a8ff;
  border-radius: 50%;
  background: #00a8ff;
  color: #fff;

  ${({ style }) => ({ ...style })};

  &.selected {
    font-weight: 900;
    background: #6144dd;
    border: 1px solid #6144dd;
  }
`
