import { QueryClient } from '@tanstack/react-query'

const queryErrorHandler = (err: unknown): void => {
  const errorMessage = err instanceof Error ? err.message : err
  console.log(errorMessage)
  // alert(errorMessage)
}

export const generateQueryClient = (): QueryClient => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        onError: queryErrorHandler,
        staleTime: 0,
        cacheTime: 300000, // 5min
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        refetchInterval: 600000, // 10min
      },
      mutations: {
        onError: queryErrorHandler,
      },
    },
  })
}
export const queryClient = generateQueryClient()
