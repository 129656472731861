import React from 'react'
import styled from 'styled-components/macro'

import { IFlex } from './Flex'
import Wrapper from './Wrapper'

export interface IPage extends IFlex {
  fullX?: boolean
  header?: boolean
  children: React.ReactNode
}

export default function Page({ fullX = true, children, ...restProps }: IPage) {
  return (
    <Wrapper direction={'column'}>
      <PageContainer fullX={fullX} direction={'column'} {...restProps}>
        {children}
      </PageContainer>
    </Wrapper>
  )
}

const PageContainer = styled(Wrapper)<IPage>`
  padding: ${({ fullX }) => !fullX && `0px 16px`};
`
