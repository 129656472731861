import { FieldValues, Path, PathValue, UseFormRegister } from 'react-hook-form'
import styled from 'styled-components/macro'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import Inputs from './Inputs'

import Flex from '../atomics/Flex'

interface IDateTimePickers<T extends FieldValues> {
  label: string
  required?: boolean
  error?: boolean
  style?: React.CSSProperties
  register?: UseFormRegister<T>
  fieldName?: Path<T>
  value?: PathValue<T, Path<T>>
}

export default function DateTimePickers<T extends FieldValues>({
  label,
  ...restProps
}: IDateTimePickers<T>) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePickerContainer flex>
        <Inputs<T> label={label} type={'datetime-local'} {...restProps} />
      </DateTimePickerContainer>
    </LocalizationProvider>
  )
}

const DateTimePickerContainer = styled(Flex)`
  .MuiFormControl-root {
    flex: 1;
  }
  .MuiInput-root {
    margin-top: 24px;
  }

  .MuiFormLabel-root.Mui-focused {
    font-weight: 700;
  }
`
