import LabelIcon from '../LabelIcon'

interface IRailLabelIcon {
  active?: boolean
}

export default function RailLabelIcon({ active = false }: IRailLabelIcon) {
  return (
    <LabelIcon
      category={'transportation'}
      type={'RAIL'}
      label={'기차'}
      active={active}
    />
  )
}
