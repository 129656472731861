import { FieldValues, Path, PathValue, UseFormRegister } from 'react-hook-form'
import styled from 'styled-components/macro'

import { CSSObject, FormHelperText, Input } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel'

interface IInputs<T extends FieldValues> {
  id?: string
  label?: string
  value?: PathValue<T, Path<T>>
  fieldName?: Path<T>
  endAdornment?: React.ReactNode
  placeholder?: string
  helperText?: string
  onClick?: () => void
  onChange?: (id: string, value: string) => void
  onFocus?: () => void
  onBlur?: () => void
  required?: boolean
  error?: boolean
  multiline?: boolean
  rows?: number | string
  color?: InputLabelProps['color']
  textAlign?: 'left' | 'right' | 'center'
  autoFocus?: boolean
  autoComplete?: string
  inputStyle?: CSSObject
  type?: string
  register?: UseFormRegister<T>
  showLabel?: boolean
  disabled?: boolean
}

export default function Inputs<T extends FieldValues>({
  id = '',
  label = '',
  value,
  fieldName = id as Path<T>,
  endAdornment,
  placeholder,
  helperText,
  onChange,
  required,
  error,
  color = 'secondary',
  textAlign = 'left',
  autoFocus = false,
  autoComplete = 'off',
  inputStyle,
  register,
  showLabel = true,
  ...restProps
}: IInputs<T>) {
  return (
    <StyledFormControl variant="standard" sx={{ flex: 1 }}>
      {showLabel && (
        <InputLabel
          shrink
          htmlFor={id}
          required={required}
          error={error}
          color={color}
        >
          {label}
        </InputLabel>
      )}
      {register ? (
        <StyledInput
          id={id}
          endAdornment={endAdornment}
          placeholder={placeholder}
          inputProps={{ 'aria-label': label, sx: { textAlign } }}
          required={required}
          error={error}
          color={color}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          sx={inputStyle}
          {...register?.(fieldName, { value })}
          {...restProps}
        />
      ) : (
        <StyledInput
          id={id}
          endAdornment={endAdornment}
          placeholder={placeholder}
          inputProps={{ 'aria-label': label, sx: { textAlign } }}
          required={required}
          error={error}
          color={color}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          sx={inputStyle}
          value={value}
          {...restProps}
        />
      )}
      {helperText && (
        <FormHelperText id="helper-text">{helperText}</FormHelperText>
      )}
    </StyledFormControl>
  )
}

const StyledFormControl = styled(FormControl)`
  /* label + div {
    margin-top: 24px;
  }

  .MuiInputLabel-root {
    font-size: 1.3rem;
  }
  .MuiInputLabel-root.Mui-focused {
    font-weight: 700;
  } */
`

const StyledInput = styled(Input)`
  /* .MuiInput-input {
    height: 2.4375em;
    font-weight: 900;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  } */
`
