
import styled from 'styled-components/macro'

import { colors } from '@mui/material'

import Flex, { DirectionType } from '../../atomics/Flex'
import Icon, { IIcon } from '../../atomics/Icon'

type PositionType = 'top' | 'bottom' | 'left' | 'right'

export interface ILabelIcon extends IIcon {
  active?: boolean
  label?: React.ReactNode
  gap?: number
  ratio?: {
    x?: number
    y?: number
  }
  position?: PositionType
  borderRadius?: number
}

export default function LabelIcon({
  category,
  type,
  active = false,
  label = null,
  gap = 4,
  ratio = {
    x: 1,
    y: 1,
  },
  position = 'top',
  borderRadius = 16,
  ...restProps
}: ILabelIcon) {
  const getDirection = (position: PositionType): DirectionType => {
    switch (position) {
      case 'top':
        return 'column'
      case 'bottom':
        return 'column-reverse'
      case 'left':
        return 'row'
      case 'right':
        return 'row-reverse'
      default:
        return 'column'
    }
  }

  const direction = getDirection(position)

  return (
    <StyledIcon
      align={'center'}
      active={active}
      ratio={ratio}
      borderRadius={borderRadius}
    >
      <Flex direction={direction} align={'center'} gap={gap}>
        <Icon category={category} type={type} {...restProps} />
        {label}
      </Flex>
    </StyledIcon>
  )
}

const StyledIcon = styled(Flex)<{
  active?: boolean
  ratio?: {
    x?: number
    y?: number
  }
  borderRadius?: number
}>`
  flex: 1;
  border: 1px solid ${({ active }) => (active ? colors.purple[700] : '')};
  color: ${({ active }) => (active ? colors.purple[700] : '')};
  font-weight: ${({ active }) => (active ? 900 : 400)};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  aspect-ratio: auto ${({ ratio }) => ratio && `${ratio.x} / ${ratio.y}`};
`
