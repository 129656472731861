import { atom } from 'recoil'

import { IPlaceSearchResult } from 'src/components/KakaoMap'
import { INITIAL_DATA } from 'src/react-query/constant'

export const geolocationAtom = atom<PosType>({
  key: 'geolocationAtom',
  default: { lat: 37.541, lng: 126.986 },
})

export const searchBarInputDomAtom = atom<HTMLInputElement | null>({
  key: 'searchBarInputDomAtom',
  default: null,
})

export const placeSearchListAtom = atom<Array<Partial<IPlaceSearchResult>>>({
  key: 'placeSearchListAtom',
  default: [],
})

export const selectedPlaceAtom = atom<Partial<IPlaceSearchResult> | null>({
  key: 'selectedPlaceAtom',
  default: null,
})

export const isOnEditAtom = atom({
  key: 'isOnEditAtom',
  default: false,
})

export const isShowPositionMarkerAtom = atom({
  key: 'isShowPositionMarkerAtom',
  default: false,
})

export const currentReportInfoAtom = atom({
  key: 'currentReportInfoAtom',
  default: INITIAL_DATA.REPORT_INFO,
})

export const scrollAtom = atom({
  key: 'scrollAtom',
  default: 0,
})
