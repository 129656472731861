import { useEffect, useState } from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import { useIsFetching, useIsMutating } from '@tanstack/react-query'

import Flex from '../atomics/Flex'

export default function Loading() {
  const isFetching = useIsFetching()
  const isMutating = useIsMutating()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(Boolean(isFetching || isMutating))
  }, [isFetching, isMutating])

  return isLoading ? (
    <Flex
      align={'center'}
      style={{
        zIndex: 9999,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <CircularProgress color="secondary" />
    </Flex>
  ) : null
}
