import { FieldValues, Path, UseFormRegister } from 'react-hook-form'

import FormControlLabel from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import Radio from '@mui/material/Radio'

import FemaleLabelIcon from '../components/icons/LabelIcons/FemaleLabelIcon'
import MaleLabelIcon from '../components/icons/LabelIcons/MaleLabelIcon'
import Radios, { IRadios } from '../components/Radios'

interface IGenderRadios<T extends FieldValues> extends IRadios {
  value?: GenderType
  fieldName?: Path<T>
  register?: UseFormRegister<T>
}

export default function GenderRadios<T extends FieldValues>({
  fieldName = 'gender' as Path<T>,
  register,
  value = 'MALE',
  ...restProps
}: IGenderRadios<T>) {
  return (
    <Radios
      label={
        <InputLabel shrink color={'secondary'}>
          {'성별'}
        </InputLabel>
      }
      defaultValue={value}
      {...restProps}
    >
      <FormControlLabel
        value="MALE"
        control={
          <Radio
            disableRipple
            checkedIcon={<MaleLabelIcon active />}
            icon={<MaleLabelIcon />}
            sx={{ flex: 1, p: 0 }}
          />
        }
        {...register?.(fieldName)}
        label="Male"
        sx={{ m: 0, flex: 1 }}
      />
      <FormControlLabel
        value="FEMALE"
        control={
          <Radio
            disableRipple
            checkedIcon={<FemaleLabelIcon active />}
            icon={<FemaleLabelIcon />}
            sx={{ flex: 1, p: 0 }}
          />
        }
        {...register?.(fieldName)}
        label="Female"
        sx={{ m: 0, flex: 1 }}
      />
    </Radios>
  )
}
