import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Button } from '@mui/material'
import dayjs from 'dayjs'

import { ReactComponent as MapMarkerGreen } from 'src/assets/svg/map_marker_gr.svg'
import Icon from 'src/atomics/Icon'
import { useLostRegister } from 'src/react-query-hooks/lost'
import { useReportRegister } from 'src/react-query-hooks/report'

import Flex from '../../atomics/Flex'
import CardStyle from '../../styles/CardStyle'
import Inputs from '../Inputs'
import { IPlaceSearchResult } from '../KakaoMap'
import UploadButtons from '../UploadButtons'

export default function ReportAddCard({
  placeInfo,
  onConfirm,
  onCancel = () => {},
}: {
  placeInfo: Partial<IPlaceSearchResult>
  onConfirm?: () => void
  onCancel?: () => void
}) {
  const DEFAULT_IMAGE =
    'https://chapteronedogtraining.com.au/wp-content/uploads/2021/08/home-puppy-1-1000.jpg'

  // const datesYYYYmmDD = dayjs(targetDate).format('YYYY.MM.DD')
  // const datesHHmm = dayjs(targetDate).format('HH:MM')
  // const today = dayjs()
  // const diff = dayjs(targetDate).diff(today, 'd')

  const { id: lostRequestInfoReportListId } = useParams()

  const { mutate: updateLostRequest } = useLostRegister({ isEdit: true })
  const { mutate: reportRegister } = useReportRegister({
    isEdit: false,
    onSuccess: onCancel,
  })

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm()
  const onSubmit = (data: any) => {
    const { report_date, report_place, report_place_detail, image } = data
    const reportInfo: ReportInfo = {
      date: report_date,
      place: {
        address: report_place,
        placeName: report_place_detail,
        location: { lat: Number(placeInfo.y), lng: Number(placeInfo.x) },
      },
      image,
      lostRequestInfoReportListId: lostRequestInfoReportListId!,
    }
    // updateLostRequest({
    //   reportList: [reportInfo]
    // })
    console.log(reportInfo)
    reportRegister(reportInfo)
  }

  useEffect(() => {
    setValue('report_place', placeInfo.address_name)
    setValue('report_place_detail', placeInfo.place_name)
  }, [placeInfo])

  return (
    <ReportAddCardContainer direction={'column'} onClick={() => {}}>
      <div className={'title__box'}>
        <MapMarkerGreen width={30} />
        <p>목격 정보 등록</p>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flex: 1 }}
      >
        <Flex flex className={'form__container'}>
          <UploadButtons
            id={'file'}
            icon={<Icon type={'ADD_IMAGE'} fontSize={'small'} />}
            showButtonName={false}
            isSquare
            buttonStyle={{
              width: 80,
              height: 80,
              borderRadius: 12,
            }}
            buttonWrapStyle={{ maxWidth: 80 }}
            fieldName={'image'}
            register={register}
            setValue={setValue}
          />
          <Flex flex direction={'column'} align={'centerY'} style={{ gap: 4 }}>
            <Inputs
              fieldName={'report_date'}
              label={'목격 날짜'}
              type={'datetime-local'}
              placeholder={'목격 날짜를 선택해주세요.'}
              register={register}
            />
            <Inputs
              label={'목격 장소'}
              fieldName={'report_place'}
              value={placeInfo.address_name}
              placeholder={'목격 장소를 입력해주세요.'}
              register={register}
            />
            <Inputs
              showLabel={false}
              fieldName={'report_place_detail'}
              value={placeInfo.place_name}
              placeholder={'자세한 주소를 입력해주세요.'}
              register={register}
            />
            <Flex className={'button__box'} align={'end'}>
              <Button onClick={onCancel}>
                <Icon type={'CLEAR'} fontSize={'small'} color={'error'} />
              </Button>
              <Button type={'submit'}>
                <Icon type={'CHECK'} fontSize={'small'} color={'success'} />
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </form>
    </ReportAddCardContainer>
  )
}

const ReportAddCardContainer = styled(CardStyle)`
  padding: 12px;
  border: 1px solid #f1f1f1;
  border-radius: 12px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
  cursor: pointer;
  gap: 12px;
  margin: 16px;

  &:hover {
  }

  .title__box {
    display: flex;
    align-itmes: center;
    gap: 4px;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .info {
    font-size: 0.9rem;
    color: #333;
  }

  .bold {
    font-weight: 700;
  }

  .image {
    width: 80px;
    height: 80px;
    border-radius: 12px;
  }

  .form__container {
    gap: 12px;

    .button__box {
      display: flex;

      button {
        padding: 8px;
        min-width: 30px;
      }
    }
  }
`
