import React, { useState } from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import { colors } from '@mui/material'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'

import Icon from '../atomics/Icon'

// enum number
enum NAV_TYPE {
  FIND_LOST,
  MAIN,
  REGISTER,
  INFO,
}

export default function Nav() {
  const navigate = useNavigate()
  const [value, setValue] = useState(NAV_TYPE.MAIN)
  const location = useLocation()

  const { pathname } = location

  useEffect(() => {
    setValue(() => {
      const page = pathname.split('/')[1]
      switch (page) {
        case '':
          return NAV_TYPE.MAIN
        case 'lost':
          return NAV_TYPE.FIND_LOST
        case 'info':
          return NAV_TYPE.INFO
        default:
          return NAV_TYPE.MAIN
      }
    })
  }, [pathname])

  return (
    <NavContainer>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(e, newValue) => setValue(newValue)}
        style={{ flex: 1 }}
      >
        <BottomNavigationAction
          label="실종동물 찾기"
          icon={<Icon category={'nav'} type={'FIND_LOST'} />}
          onClick={() => {
            if (value === NAV_TYPE.FIND_LOST) return
            navigate('/lost')
          }}
          style={{ gap: 3 }}
        />
        <BottomNavigationAction
          label="이동봉사 신청"
          icon={<Icon category={'nav'} type={'PETS'} />}
          onClick={() => {
            if (value === NAV_TYPE.MAIN) return
            navigate('/')
          }}
          style={{ gap: 3 }}
        />
        {/* <BottomNavigationAction
          label="내 정보"
          icon={<Icon category={'nav'} type={'SETTING'} />}
          onClick={() => {
            if (value === NAV_TYPE.INFO) return
            navigate('/info')
          }}
          style={{ gap: 3 }}
        /> */}
      </BottomNavigation>
    </NavContainer>
  )
}
const NavContainer = styled.nav`
  position: sticky;
  bottom: 0px;
  display: flex;
  flex: 1;
  max-height: 60px;
  background: #fff;
  min-height: 60px;
  z-index: 10;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -3px 10px rgb(0 0 0 / 10%);

  .MuiBottomNavigation-root {
    justify-content: space-evenly;
  }

  .MuiBottomNavigationAction-root.Mui-selected {
    color: ${colors.purple[700]};
  }
`
