import LabelIcon from '../LabelIcon'

interface IFemaleLabelIcon {
  active?: boolean
}

export default function FemaleLabelIcon({ active = false }: IFemaleLabelIcon) {
  return (
    <LabelIcon
      category={'gender'}
      type={'FEMALE'}
      label={'여아'}
      ratio={{ x: 1, y: 0.25 }}
      position={'left'}
      active={active}
      borderRadius={12}
    />
  )
}
