import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { StyledEngineProvider } from '@mui/styled-engine'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Amplify } from 'aws-amplify'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'

import App from './App'
import Menu from './atomics/Menu'
import Modal from './atomics/Modal'
import awsExports from './aws-exports'
import { queryClient } from './react-query/queryClient'
import GlobalStyles from './styles/GlobalStyles'
import ScrollToTop from './utils/ScrollToTop'

Amplify.configure(awsExports)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <RecoilRoot>
        <StyledEngineProvider injectFirst>
          <GlobalStyles />
          <App />
          <Menu />
          <Modal />
          <ScrollToTop />
        </StyledEngineProvider>
      </RecoilRoot>
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)
