import { CSSObject } from '@mui/material'
import { atom } from 'recoil'

export interface ModalInfo {
  show: boolean
  body: React.ReactNode
  closeOnBackdropClick?: boolean
  sx?: CSSObject
  modalWrapperStyle?: React.CSSProperties
}

export const modalAtom = atom<ModalInfo>({
  key: 'modalAtom',
  default: {
    show: false,
    body: null,
    sx: {},
    modalWrapperStyle: {},
    closeOnBackdropClick: true,
  },
})
