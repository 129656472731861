import { useEffect } from 'react'
import styled from 'styled-components/macro'

import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd'
import { useRecoilState, useSetRecoilState } from 'recoil'

import ReportCard from './ReportCard'
import Flex from 'src/atomics/Flex'
import Icon from 'src/atomics/Icon'
import { currentReportInfoAtom } from 'src/stores/commonStores'
import { centerPositionAtom } from 'src/stores/mapStores'
import CardStyle from 'src/styles/CardStyle'

interface IReportList {
  reportList: Array<ReportInfo>
  onDragEnd?: OnDragEndResponder
  onAdd?: () => void
  isDragDisabled?: boolean
  showIcon?: boolean
}

function ReportList({
  reportList,
  onDragEnd = () => {},
  onAdd = () => {},
  showIcon = false,
  isDragDisabled = true,
}: IReportList) {
  const [currentReport, setCurrentReport] = useRecoilState(
    currentReportInfoAtom
  )
  const setCenterPosition = useSetRecoilState(centerPositionAtom)

  useEffect(() => {
    if (reportList.length > 0) {
      setCurrentReport(reportList[0])
    }
  }, [reportList])

  return (
    <ReportListContainer>
      <div className={'title'}>제보 리스트</div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="reports">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ overflowY: 'auto' }}
            >
              {reportList.length > 0 ? (
                reportList.map((report, index) => (
                  <Draggable
                    key={report.id}
                    isDragDisabled={isDragDisabled}
                    draggableId={report.id?.toString() ?? ''}
                    index={index}
                  >
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ReportCard
                          index={index}
                          onClick={() => {
                            setCurrentReport(report)
                            setCenterPosition({
                              lat: report.place.location.lat,
                              lng: report.place.location.lng,
                            })
                          }}
                          report={report}
                          showIcon={showIcon}
                          isSelected={report?.id === currentReport?.id}
                        />
                      </li>
                    )}
                  </Draggable>
                ))
              ) : (
                <EmptyReportCardStyle
                  align={'center'}
                  gap={8}
                  style={{ color: '#666' }}
                  onClick={onAdd}
                >
                  <Flex>
                    <Icon type={'ADD_CIRCLE_OUTLINE'} fontSize={'small'} />
                  </Flex>
                  <Flex>제보 등록</Flex>
                </EmptyReportCardStyle>
              )}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </ReportListContainer>
  )
}

export default ReportList

const ReportListContainer = styled.div`
  position: relative;

  .title {
    padding: 12px;
    font-size: 1.1rem;
    font-weight: 500;
    background: #fff;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 0.5rem 0.5rem 0.5rem;
  }
`

const EmptyReportCardStyle = styled(CardStyle)`
  padding: 16px;
  margin: 16px;
  border: 1px solid #fafafa;
`
