import styled from 'styled-components/macro'

import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'

import Flex from '../atomics/Flex'

export interface IRadios {
  label?: React.ReactNode
  defaultValue?: string
  children?: React.ReactNode
  gap?: number
}

export default function Radios({
  label = null,

  defaultValue,
  gap = 8,

  children,
}: IRadios) {
  return (
    <StyledFormControl variant="standard" sx={{ flex: 1 }}>
      {label}
      <RadioGroup
        aria-labelledby="radio-buttons-group-label"
        defaultValue={defaultValue}
        name="radio-buttons-group"
      >
        <Flex align={'center'} flex gap={gap}>
          {children}
        </Flex>
      </RadioGroup>
    </StyledFormControl>
  )
}

const StyledFormControl = styled(FormControl)`
  label + div {
    margin-top: 32px;
    flex-wrap: unset;
  }

  label > span:last-child {
    display: none;
  }

  /* .MuiInputLabel-root {
    font-size: 1.3rem;
  }
  .MuiInputLabel-root.Mui-focused {
    font-weight: 700;
  } */
`
