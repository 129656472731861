import { atom } from 'recoil'

export interface MenuInfo {
  anchorEl: null | HTMLElement
  menuList: Array<{ onClick?: () => void; item: React.ReactNode }>
  menuStyle?: React.CSSProperties
  menuListStyle?: React.CSSProperties
}

export const menuAtom = atom<MenuInfo>({
  key: 'menuAtom',
  default: {
    anchorEl: null,
    menuList: [],
    menuStyle: {},
    menuListStyle: {},
  },
})
