import { IconProps } from '@mui/material'
import { SvgIconPropsSizeOverrides } from '@mui/material/SvgIcon/SvgIcon'
import { OverridableStringUnion } from '@mui/types'

import MUIIcons from './icons'

export interface IIcon {
  category?:
    | 'requestType'
    | 'gender'
    | 'transportation'
    | 'tripType'
    | 'nav'
    | 'common'
  type: string
  fontSize?: OverridableStringUnion<
    'inherit' | 'large' | 'medium' | 'small',
    SvgIconPropsSizeOverrides
  >
  color?: IconProps['color']
  className?: string
  style?: React.CSSProperties
}

export default function Icon({
  category = 'common',
  type,
  ...restProps
}: IIcon) {
  const Element = MUIIcons[category][type]
  return <Element {...restProps} />
}
