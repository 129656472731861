/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMoveRequestInfo = /* GraphQL */ `
  mutation CreateMoveRequestInfo(
    $input: CreateMoveRequestInfoInput!
    $condition: ModelMoveRequestInfoConditionInput
  ) {
    createMoveRequestInfo(input: $input, condition: $condition) {
      id
      moveInfo {
        requestType
        transportation
        tripType
        startPoint
        endPoint
        date
      }
      companionAnimalInfo {
        name
        age
        gender
        breed
        weight
        memo
        image
      }
      status
      createdAt
      updatedAt
    }
  }
`
export const updateMoveRequestInfo = /* GraphQL */ `
  mutation UpdateMoveRequestInfo(
    $input: UpdateMoveRequestInfoInput!
    $condition: ModelMoveRequestInfoConditionInput
  ) {
    updateMoveRequestInfo(input: $input, condition: $condition) {
      id
      moveInfo {
        requestType
        transportation
        tripType
        startPoint
        endPoint
        date
      }
      companionAnimalInfo {
        name
        age
        gender
        breed
        weight
        memo
        image
      }
      status
      createdAt
      updatedAt
    }
  }
`
export const deleteMoveRequestInfo = /* GraphQL */ `
  mutation DeleteMoveRequestInfo(
    $input: DeleteMoveRequestInfoInput!
    $condition: ModelMoveRequestInfoConditionInput
  ) {
    deleteMoveRequestInfo(input: $input, condition: $condition) {
      id
      moveInfo {
        requestType
        transportation
        tripType
        startPoint
        endPoint
        date
      }
      companionAnimalInfo {
        name
        age
        gender
        breed
        weight
        memo
        image
      }
      status
      createdAt
      updatedAt
    }
  }
`
export const createLostRequestInfo = /* GraphQL */ `
  mutation CreateLostRequestInfo(
    $input: CreateLostRequestInfoInput!
    $condition: ModelLostRequestInfoConditionInput
  ) {
    createLostRequestInfo(input: $input, condition: $condition) {
      id
      lostInfo {
        lostDate
        lostPlace {
          address
          placeName
          location {
            lat
            lng
          }
        }
        contact
      }
      companionAnimalInfo {
        name
        age
        gender
        breed
        weight
        memo
        image
      }
      reportList {
        items {
          id
          image
          date
          place {
            address
            placeName
            location {
              lat
              lng
            }
          }
          createdAt
          updatedAt
          lostRequestInfoReportListId
        }
        nextToken
      }
      status
      createdAt
      updatedAt
    }
  }
`
export const updateLostRequestInfo = /* GraphQL */ `
  mutation UpdateLostRequestInfo(
    $input: UpdateLostRequestInfoInput!
    $condition: ModelLostRequestInfoConditionInput
  ) {
    updateLostRequestInfo(input: $input, condition: $condition) {
      id
      lostInfo {
        lostDate
        lostPlace {
          address
          placeName
          location {
            lat
            lng
          }
        }
        contact
      }
      companionAnimalInfo {
        name
        age
        gender
        breed
        weight
        memo
        image
      }
      reportList {
        items {
          id
          image
          date
          place {
            address
            placeName
            location {
              lat
              lng
            }
          }
          createdAt
          updatedAt
          lostRequestInfoReportListId
        }
        nextToken
      }
      status
      createdAt
      updatedAt
    }
  }
`
export const deleteLostRequestInfo = /* GraphQL */ `
  mutation DeleteLostRequestInfo(
    $input: DeleteLostRequestInfoInput!
    $condition: ModelLostRequestInfoConditionInput
  ) {
    deleteLostRequestInfo(input: $input, condition: $condition) {
      id
      lostInfo {
        lostDate
        lostPlace {
          address
          placeName
          location {
            lat
            lng
          }
        }
        contact
      }
      companionAnimalInfo {
        name
        age
        gender
        breed
        weight
        memo
        image
      }
      reportList {
        items {
          id
          image
          date
          place {
            address
            placeName
            location {
              lat
              lng
            }
          }
          createdAt
          updatedAt
          lostRequestInfoReportListId
        }
        nextToken
      }
      status
      createdAt
      updatedAt
    }
  }
`
export const createReportInfo = /* GraphQL */ `
  mutation CreateReportInfo(
    $input: CreateReportInfoInput!
    $condition: ModelReportInfoConditionInput
  ) {
    createReportInfo(input: $input, condition: $condition) {
      id
      image
      date
      place {
        address
        placeName
        location {
          lat
          lng
        }
      }
      createdAt
      updatedAt
      lostRequestInfoReportListId
    }
  }
`
export const updateReportInfo = /* GraphQL */ `
  mutation UpdateReportInfo(
    $input: UpdateReportInfoInput!
    $condition: ModelReportInfoConditionInput
  ) {
    updateReportInfo(input: $input, condition: $condition) {
      id
      image
      date
      place {
        address
        placeName
        location {
          lat
          lng
        }
      }
      createdAt
      updatedAt
      lostRequestInfoReportListId
    }
  }
`
export const deleteReportInfo = /* GraphQL */ `
  mutation DeleteReportInfo(
    $input: DeleteReportInfoInput!
    $condition: ModelReportInfoConditionInput
  ) {
    deleteReportInfo(input: $input, condition: $condition) {
      id
      image
      date
      place {
        address
        placeName
        location {
          lat
          lng
        }
      }
      createdAt
      updatedAt
      lostRequestInfoReportListId
    }
  }
`
