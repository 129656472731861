// requestType
import AddIcon from '@mui/icons-material/Add'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive'
import CheckIcon from '@mui/icons-material/Check'
import CircleIcon from '@mui/icons-material/Circle'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import DirectionsTransitFilledIcon from '@mui/icons-material/DirectionsTransitFilled'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import DriveEtaIcon from '@mui/icons-material/DriveEta'
import EditIcon from '@mui/icons-material/Edit'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FemaleIcon from '@mui/icons-material/Female'
import FlightIcon from '@mui/icons-material/Flight'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ListIcon from '@mui/icons-material/List'
import MaleIcon from '@mui/icons-material/Male'
import MapIcon from '@mui/icons-material/Map'
import MenuIcon from '@mui/icons-material/Menu'
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation'
import PetsIcon from '@mui/icons-material/Pets'
import PlaceIcon from '@mui/icons-material/Place'
import SearchIcon from '@mui/icons-material/Search'
import SettingsIcon from '@mui/icons-material/Settings'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave'
import TramIcon from '@mui/icons-material/Tram'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'
import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

export interface IMUIIcons {
  [key: string]: {
    [key: string]: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
      muiName: string
    }
  }
}

const MUIIcons: IMUIIcons = {
  requestType: { DOMESTIC: TimeToLeaveIcon, ABROAD: AirplanemodeActiveIcon },
  gender: { MALE: MaleIcon, FEMALE: FemaleIcon },
  transportation: {
    CAR: DriveEtaIcon,
    RAIL: DirectionsTransitFilledIcon,
    AIR: FlightIcon,
    PUBLIC: TramIcon,
  },
  tripType: { ONEWAY: TrendingFlatIcon, ROUND: SyncAltIcon },
  nav: {
    PETS: PetsIcon,
    ADD: AddCircleIcon,
    SETTING: SettingsIcon,
    FIND_LOST: EmojiPeopleIcon,
  },
  common: {
    EDIT: EditIcon,
    DELETE: DeleteIcon,
    DRAG: DragIndicatorIcon,
    MARKER: PlaceIcon,
    FIND_LOCATION: NotListedLocationIcon,
    LIST: ListIcon,
    MAP: MapIcon,
    ADD_CIRCLE_OUTLINE: AddCircleOutlineIcon,
    CLEAR: ClearIcon,
    CHECK: CheckIcon,
    ADD_IMAGE: AddPhotoAlternateIcon,
    GPS_FIXED_ICON: GpsFixedIcon,
    GPS_NOT_FIXED_ICON: GpsNotFixedIcon,
    CIRCLE: CircleIcon,
    SEARCH: SearchIcon,
    EXPAND_MORE_ICON: ExpandMoreIcon,
    ARROW_UP: KeyboardArrowUpIcon,
    ARROW_DOWN: KeyboardArrowDownIcon,
    ADD: AddIcon,
    MENU: MenuIcon,
  },
}

export default MUIIcons
