import LabelIcon from '../LabelIcon'

interface IRoundLabelIcon {
  active?: boolean
}

export default function RoundLabelIcon({ active = false }: IRoundLabelIcon) {
  return (
    <LabelIcon
      category={'tripType'}
      type={'ROUND'}
      label={'왕복'}
      ratio={{ x: 1, y: 0.25 }}
      active={active}
      position={'left'}
      borderRadius={12}
    />
  )
}
