import { useEffect, useRef } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components/macro'

import { Button, Drawer } from '@mui/material'
import { OnDragEndResponder } from 'react-beautiful-dnd'
import { useRecoilState } from 'recoil'

import Flex from 'src/atomics/Flex'
import Icon from 'src/atomics/Icon'
import { selectedPlaceAtom } from 'src/stores/commonStores'
import CardStyle from 'src/styles/CardStyle'

import Inputs from '../Inputs'
import KakaoMap, { IPlaceSearchResult } from '../KakaoMap'

type PlaceFormType = Pick<Partial<IPlaceSearchResult>, 'place_name'>

export default function PlaceDrawer({
  open,
  onAdd = () => {},
  onClose,
  onDragEnd = () => {},
}: {
  open: boolean
  onAdd?: (place: Partial<IPlaceSearchResult>) => void
  onClose?: () => void
  onDragEnd?: OnDragEndResponder
}) {
  const ref = useRef<HTMLDivElement>(null)
  const [selectedPlace, setSelectedPlace] = useRecoilState(selectedPlaceAtom)

  const { register, handleSubmit } = useForm<PlaceFormType>()
  const onSubmit: SubmitHandler<PlaceFormType> = (values) => {
    const { place_name } = values
    if (Boolean(selectedPlace)) {
      const targetPlace = {
        ...selectedPlace,
        place_name,
      }
      onAdd?.(targetPlace)
      setSelectedPlace(targetPlace)
    }
    onClose?.()
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0
    }
  }, [])

  return (
    <Drawer
      anchor={'bottom'}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '10px 10px 0 0',
          height: 'calc(100vh - 120px)',
          minHeight: '60%',
        },
        ref,
      }}
    >
      <ReportAddDrawerContainer>
        <div className={'sticky__container'}>
          <Flex className={'title__box'} onClick={onClose}>
            실종 장소 검색
          </Flex>
          <KakaoMap
            search
            positionMarker
            onSelect={(place) => setSelectedPlace(place)}
          />
        </div>
        {selectedPlace && (
          <div className={'form__container'}>
            <PlaceSearchCardBox>
              <PlaceSearchCard>
                <Flex direction={'column'} gap={16} flex>
                  <Inputs label={'주소'} value={selectedPlace.address_name} />
                  <Inputs<PlaceFormType>
                    label={'상세주소'}
                    fieldName={'place_name'}
                    register={register}
                    value={selectedPlace.place_name}
                    placeholder={'상세주소를 입력해 주세요.'}
                  />
                </Flex>
              </PlaceSearchCard>
            </PlaceSearchCardBox>
            <Button type={'button'} onClick={handleSubmit(onSubmit)}>
              <Icon type={'CHECK'} fontSize={'small'} color={'success'} />
            </Button>
          </div>
        )}
      </ReportAddDrawerContainer>
    </Drawer>
  )
}

const ReportAddDrawerContainer = styled.div`
  position: relative;

  .sticky__container {
    position: sticky;
    top: 0;
    z-index: 20;

    .title__box {
      padding: 12px;
      font-size: 1.1rem;
      font-weight: 500;
      background: #fff;
    }
  }

  .form__container {
    display: flex;
    flex-direction: column;
  }
`

const PlaceSearchCardBox = styled.div`
  padding: 1rem;
`

const PlaceSearchCard = styled(CardStyle)`
  padding: 1rem;
`
