import LabelIcon from '../LabelIcon'

interface IAirLabelIcon {
  active?: boolean
}

export default function AirLabelIcon({ active = false }: IAirLabelIcon) {
  return (
    <LabelIcon
      category={'transportation'}
      type={'AIR'}
      label={'항공'}
      active={active}
    />
  )
}
