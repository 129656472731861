import { useEffect, useState } from 'react'

const useGeoLocation = (options = {}) => {
  const [pos, setPos] = useState<PosType>({ lat: 37.541, lng: 126.986 })
  const [error, setError] = useState('')

  const handleSuccess = (pos: GeolocationPosition) => {
    const { latitude, longitude } = pos.coords
    setPos({ lat: latitude, lng: longitude })
  }

  const handleError = (error: GeolocationPositionError) => {
    setError(error.message)
  }

  useEffect(() => {
    const { geolocation } = navigator

    if (!geolocation) {
      setError('Geolocation is not supported.')
      return
    }
    navigator.geolocation.getCurrentPosition(
      handleSuccess,
      handleError,
      options
    )
  }, [])

  return { pos, error }
}

export default useGeoLocation
