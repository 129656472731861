import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components/macro'

import { DevTool } from '@hookform/devtools'
import { Button } from '@mui/material'
import { useRecoilValue } from 'recoil'

import Icon from 'src/atomics/Icon'
import { useReportRegister } from 'src/react-query-hooks/report'
import {
  searchBarInputDomAtom,
  selectedPlaceAtom,
} from 'src/stores/commonStores'

import Flex from '../../atomics/Flex'
import CardStyle from '../../styles/CardStyle'
import Inputs from '../Inputs'
import UploadButtons from '../UploadButtons'

export default function ReportEditCard({
  reportInfo,
  onConfirm,
  onCancel = () => {},
}: {
  reportInfo: ReportInfo
  onConfirm?: () => void
  onCancel?: () => void
}) {
  const searchBarDom = useRecoilValue(searchBarInputDomAtom)
  const selectedPlace = useRecoilValue(selectedPlaceAtom)

  const { mutate: updateLostRequest } = useReportRegister({
    isEdit: true,
    onSuccess: onCancel,
  })

  const handleFocusPlace = (dom: HTMLInputElement | null) => {
    if (dom) {
      dom.focus()
    }
  }

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isDirty: isFormDirty },
  } = useForm()
  const onSubmit = (data: any) => {
    const { report_date, report_place, report_place_detail, image } = data
    const { id, lostRequestInfoReportListId } = reportInfo

    const updateInfo: ReportInfo = {
      id,
      date: report_date,
      place: {
        address: report_place,
        placeName: report_place_detail,
        location: {
          lat: Number(selectedPlace?.y || 0),
          lng: Number(selectedPlace?.x || 0),
        },
      },
      image,
      lostRequestInfoReportListId,
    }
    updateLostRequest(updateInfo)
  }

  const [isDirty, setIsDirty] = useState(isFormDirty)

  useEffect(() => {
    if (!selectedPlace) return
    const { place_name, address_name } = selectedPlace
    setValue('report_place', address_name)
    setValue('report_place_detail', place_name)
  }, [selectedPlace])

  useEffect(() => {
    const { image } = watch()
    setIsDirty(image !== reportInfo.image)
  }, [watch()])

  return (
    <ReportEditCardContainer
      direction={'column'}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <div className={'title__box'}>목격 정보 수정</div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flex: 1 }}
      >
        <Flex flex className={'form__container'}>
          <UploadButtons
            id={'file'}
            value={reportInfo.image}
            icon={<Icon type={'ADD_IMAGE'} fontSize={'small'} />}
            showButtonName={false}
            isSquare
            buttonStyle={{
              width: 80,
              height: 80,
              borderRadius: 12,
            }}
            buttonWrapStyle={{ maxWidth: 80 }}
            fieldName={'image'}
            register={register}
            setValue={setValue}
          />
          <Flex flex direction={'column'} align={'centerY'} style={{ gap: 4 }}>
            <Inputs
              fieldName={'report_date'}
              label={'목격 날짜'}
              value={reportInfo.date}
              type={'datetime-local'}
              placeholder={'목격 날짜를 선택해주세요.'}
              register={register}
            />
            <Inputs
              label={'목격 장소'}
              fieldName={'report_place'}
              value={reportInfo.place.address}
              onFocus={() => handleFocusPlace(searchBarDom)}
              placeholder={'목격 장소를 입력해주세요.'}
              register={register}
            />
            <Inputs
              showLabel={false}
              fieldName={'report_place_detail'}
              value={reportInfo.place.placeName}
              // onFocus={() => handleFocusPlace(searchBarDom)}
              placeholder={'자세한 주소를 입력해주세요.'}
              register={register}
            />
            <Flex className={'button__box'} align={'between'}>
              <Button onClick={onCancel}>
                <Icon type={'CLEAR'} fontSize={'small'} color={'error'} />
              </Button>
              <Button
                type={'submit'}
                // disabled={!isDirty}
                sx={{ '&.Mui-disabled': { background: '#888' } }}
              >
                <Icon type={'CHECK'} fontSize={'small'} color={'success'} />
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </form>
      <DevTool control={control} />
    </ReportEditCardContainer>
  )
}

const ReportEditCardContainer = styled(CardStyle)`
  padding: 12px;
  border: 1px solid #f1f1f1;
  border-radius: 12px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
  cursor: pointer;
  gap: 12px;
  margin: 16px;

  &:hover {
  }

  .title__box {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .info {
    font-size: 0.9rem;
    color: #333;
  }

  .bold {
    font-weight: 700;
  }

  .image {
    width: 80px;
    height: 80px;
    border-radius: 12px;
  }

  .form__container {
    gap: 12px;

    .button__box {
      display: flex;

      button {
        padding: 8px;
        min-width: 30px;
      }
    }
  }
`
