import Chip, { ChipProps } from '@mui/material/Chip'

export default function Chips({
  label = 'secondary',
  color = 'secondary',
  variant = 'outlined',
  ...restProps
}: ChipProps) {
  return <Chip label={label} color={color} variant={variant} {...restProps} />
}
