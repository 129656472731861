export interface IImage extends React.HTMLAttributes<HTMLImageElement> {
  src: string
  alt: string
  width?: number | string
  height?: number | string
  className?: string
}

export default function Image({
  src,
  alt,
  width = '100%',
  height = '100%',
  ...restProps
}: IImage) {
  return (
    <div>
      <img src={src} alt={alt} width={width} height={height} {...restProps} />
    </div>
  )
}
