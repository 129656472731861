import { createGlobalStyle } from 'styled-components'

import reset from 'styled-reset'

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  html {
    display: flex;
    height: 100vh;
  }
  
  body {
    font-family: 'Noto Sans KR', sans-serif;
    line-height: 1.5;
    display: flex;
    justify-content: center;
    flex: 1;
    background: #f1f1f1;
  }
  
  button {
    cursor: pointer;
  }
  
  #root {
    background: #fff;
    display: flex;
    flex: 1;
    max-width: 800px;
    overflow-y: auto;
  }
`

export default GlobalStyle
