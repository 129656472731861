import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { API, graphqlOperation } from 'aws-amplify'

import { deleteReportInfo, updateReportInfo } from './../graphql/mutations'
import { LOST_REQUEST_KEYS } from './lost'
import { createReportInfo } from 'src/graphql/mutations'
import { getReportInfo, listReportInfos } from 'src/graphql/queries'

import { INITIAL_DATA } from '../react-query/constant'

const REPORT_KEYS = {
  all: ['REPORT'],
  lists: () => [...REPORT_KEYS.all, 'list'],
  list: (filters?: string) => [...REPORT_KEYS.lists(), { filters }],
  details: () => [...REPORT_KEYS.all, 'detail'],
  detail: (id: string) => [...REPORT_KEYS.details(), id],
}

// createFn
async function createReportRegister(registerInfo: ReportInfo) {
  await API.graphql(graphqlOperation(createReportInfo, { input: registerInfo }))
}

// updateFn
async function updateReportRegister(updateInfo: ReportInfo) {
  await API.graphql(graphqlOperation(updateReportInfo, { input: updateInfo }))
}

// listFn
async function listReportRequest(): Promise<Array<ReportInfo>> {
  const {
    data: {
      listReportInfos: { items },
    },
  }: any = await API.graphql(graphqlOperation(listReportInfos))
  return items
}

// detailFn
async function getReport(id: string): Promise<ReportInfo> {
  const { data }: any = await API.graphql(
    graphqlOperation(getReportInfo, { id })
  )
  return data.getReportInfo
}

// deleteFn
async function deleteReport(id: string) {
  await API.graphql(graphqlOperation(deleteReportInfo, { input: { id } }))
}

// registerHook
export function useReportRegister({
  isEdit,
  onSuccess,
}: {
  isEdit: boolean
  onSuccess?: () => void
}) {
  const queryClient = useQueryClient()
  return useMutation(
    isEdit
      ? (updateInfo: ReportInfo) => updateReportRegister(updateInfo)
      : (registerInfo: ReportInfo) => createReportRegister(registerInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(LOST_REQUEST_KEYS.details())
        onSuccess?.()
      },
    }
  )
}

// getListHook
export function useReportList() {
  const fallback: [] = []
  const { data = fallback, ...restFn } = useQuery(
    [REPORT_KEYS.details()],
    listReportRequest
  )
  return { data, ...restFn }
}

// getDetailHook
export function useReportDetail(
  id: string,
  onSuccess?: (data: ReportInfo) => void
) {
  const fallback: ReportInfo = INITIAL_DATA.REPORT_INFO

  const { data = fallback, ...restFn } = useQuery(
    REPORT_KEYS.detail(id),
    () => getReport(id),
    {
      onSuccess: (res) => {
        onSuccess?.(res)
      },
    }
  )
  return { data, ...restFn }
}

// deleteHook
export function useDeleteReport({
  id,
  onSuccess,
}: {
  id: string
  onSuccess?: () => void
}) {
  const queryClient = useQueryClient()
  return useMutation(() => deleteReport(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(LOST_REQUEST_KEYS.details())
      onSuccess?.()
    },
  })
}
