import LabelIcon from '../LabelIcon'

interface ICarLabelIcon {
  active?: boolean
}

export default function CarLabelIcon({ active = false }: ICarLabelIcon) {
  return (
    <LabelIcon
      category={'transportation'}
      type={'CAR'}
      label={'자동차'}
      active={active}
    />
  )
}
