import { atom } from 'recoil'

export const initialRequestInfo: MoveInfo = {
  requestType: 'DOMESTIC',
  transportation: 'CAR',
  tripType: 'ONEWAY',
  startPoint: '',
  endPoint: '',
  date: '',
}

export const requestInfoAtom = atom<MoveInfo>({
  key: 'requestInfoAtom',
  default: initialRequestInfo,
})

export const initialDogInfo: companionAnimalInfo = {
  name: '',
  age: 0,
  gender: 'MALE',
  breed: '',
  weight: 0,
  memo: '',
  image: '',
}

export const lostRequestListInfoAtom = atom<Array<LostRequestInfo>>({
  key: 'lostRequestListInfoAtom',
  default: [],
})
