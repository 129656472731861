import Flex from '../atomics/Flex'

interface IDetailPanel {
  title?: string
  condition?: boolean
  children: React.ReactNode
}

export default function DetailPanel({
  title,
  condition = true,
  children,
}: IDetailPanel) {
  return (
    <>
      {condition ? (
        <Flex direction={'column'} style={{ paddingTop: 16 }} gap={8}>
          {Boolean(title) ? (
            <Flex className={'title'} flex>
              {title}
            </Flex>
          ) : null}
          {children}
        </Flex>
      ) : null}
    </>
  )
}
