import styled from 'styled-components/macro'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Divider } from '@mui/material'
import dayjs from 'dayjs'

import Flex from 'src/atomics/Flex'
import Icon from 'src/atomics/Icon'
import CardStyle from 'src/styles/CardStyle'

import Chips from '../Chips'

export default function MoveInfoCard({
  moveRequestInfo,
  onClick = () => {},
}: {
  moveRequestInfo: MoveRequestInfo
  onClick: (moveInfo: MoveRequestInfo) => void
}) {
  const { moveInfo, companionAnimalInfo } = moveRequestInfo
  const { name, age, gender, breed, weight, image } = companionAnimalInfo
  const {
    requestType,
    tripType,
    date: targetDate,
    startPoint,
    endPoint,
  } = moveInfo
  const DEFAULT_IMAGE =
    'https://chapteronedogtraining.com.au/wp-content/uploads/2021/08/home-puppy-1-1000.jpg'

  const datesYYYYmmDD = dayjs(targetDate).format('YYYY.MM.DD')
  const datesHHmm = dayjs(targetDate).format('HH:MM')
  const today = dayjs()
  const diff = dayjs(targetDate).diff(today, 'd')
  return (
    <MoveInfoCardContainer onClick={() => onClick(moveRequestInfo)}>
      <Flex>
        <img
          src={image || DEFAULT_IMAGE}
          onError={(e) => {
            // @ts-ignore
            e.target.src = DEFAULT_IMAGE
          }}
          alt={'img'}
          className={'image'}
        />
      </Flex>
      <Flex flex direction={'column'} gap={4}>
        <Flex gap={4} align={'centerY'}>
          <Flex className={'info bold'}>{name}</Flex>
          <Divider sx={{ height: 12, m: 0.5 }} orientation="vertical" />
          <Flex className={'info'}>{age}세</Flex>
          <Divider sx={{ height: 12, m: 0.5 }} orientation="vertical" />
          <Flex className={'info'}>{weight}kg</Flex>
          <Divider sx={{ height: 12, m: 0.5 }} orientation="vertical" />
          <Flex className={'info'}>
            <Icon category={'gender'} type={gender || 'MALE'} />
          </Flex>
        </Flex>
        <Flex align={'centerY'}>
          <Flex gap={4} align={'centerY'}>
            <Flex className={'info'}>{startPoint}</Flex>
            <ArrowForwardIcon sx={{ fontSize: '1rem' }} />
            <Flex className={'info'}>{endPoint}</Flex>
          </Flex>
          <Divider sx={{ height: 12, m: 1 }} orientation="vertical" />
          <Flex className={'info'}>3.3km</Flex>
        </Flex>
        <Flex align={'centerY'}>
          <Flex className={'info'}>
            <Chips label={'OPEN'} color={'success'} size={'small'} />
          </Flex>
          <Divider sx={{ height: 12, m: 1 }} orientation="vertical" />
          <Flex className={'info'}>
            <Icon category={'tripType'} type={tripType || 'ONEWAY'} />
          </Flex>
          <Divider sx={{ height: 12, m: 1 }} orientation="vertical" />
          <Flex className={'info'}>
            <Icon category={'requestType'} type={requestType || 'DOMESTIC'} />
          </Flex>
        </Flex>
        <Flex>
          <Flex className={'info'}>{datesYYYYmmDD}</Flex>
          <Divider sx={{ height: 12, m: 1 }} orientation="vertical" />
          <Flex className={'info'}>{datesHHmm}</Flex>
          <Divider sx={{ height: 12, m: 1 }} orientation="vertical" />
          <Flex className={'info'}>D-{diff < 1 ? 'day' : diff + 1}</Flex>
        </Flex>
      </Flex>
    </MoveInfoCardContainer>
  )
}

const MoveInfoCardContainer = styled(CardStyle)`
  padding: 16px;
  border: 1px solid #f1f1f1;
  border-radius: 12px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
  cursor: pointer;
  gap: 16px;

  &:hover {
  }

  .info {
    font-size: 0.9rem;
    color: #333;
  }

  .bold {
    font-weight: 700;
  }

  .image {
    width: 100px;
    height: 100px;
    border-radius: 12px;
  }
`
