import LabelIcon from '../LabelIcon'

interface IOnewayLabelIcon {
  active?: boolean
}

export default function OnewayLabelIcon({ active = false }: IOnewayLabelIcon) {
  return (
    <LabelIcon
      category={'tripType'}
      type={'ONEWAY'}
      label={'단방향'}
      ratio={{ x: 1, y: 0.25 }}
      active={active}
      position={'left'}
      borderRadius={12}
    />
  )
}
