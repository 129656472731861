import LabelIcon from '../LabelIcon'

interface IAbroadLabelIcon {
  active?: boolean
}

export default function AbroadLabelIcon({ active = false }: IAbroadLabelIcon) {
  return (
    <LabelIcon
      category={'requestType'}
      type={'ABROAD'}
      label={'해외'}
      ratio={{ x: 1, y: 0.25 }}
      active={active}
      position={'left'}
      borderRadius={12}
    />
  )
}
