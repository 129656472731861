import styled from 'styled-components/macro'

import { Menu as MUIMenu, MenuItem } from '@mui/material'
import { useRecoilState } from 'recoil'

import { menuAtom } from 'src/stores/menuStores'

export default function Menu() {
  const [menuState, setMenuState] = useRecoilState(menuAtom)
  const open = Boolean(menuState.anchorEl)

  const handleClose = () => setMenuState((p) => ({ ...p, anchorEl: null }))

  return (
    <MUIMenu
      open={open}
      anchorEl={menuState.anchorEl}
      onClose={handleClose}
      PaperProps={{ style: menuState.menuStyle }}
    >
      <MenuListContainer style={menuState.menuListStyle}>
        {menuState.menuList.map((menu, idx) => (
          <MenuItem
            key={idx}
            onClick={() => {
              // handleClose()
              menu.onClick?.()
            }}
            disableRipple
            sx={{ display: 'flex', pl: 0, pr: 0 }}
          >
            {menu.item}
          </MenuItem>
        ))}
      </MenuListContainer>
    </MUIMenu>
  )
}

const MenuListContainer = styled.div<{ style?: React.CSSProperties }>`
  ${({ style }) => style && { ...style }}
`
