import { Outlet } from 'react-router-dom'

import Header from 'src/components/Header'
import Nav from 'src/components/Nav'

export default function MainLayout() {
  return (
    <>
      <Header />
      <Outlet />
      <Nav />
    </>
  )
}
