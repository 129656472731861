import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Drawer, colors } from '@mui/material'

import Info from 'src/pages/Info'
import { useDeleteLostRequest } from 'src/react-query-hooks/lost'
import { useDeleteMoveRequest } from 'src/react-query-hooks/move'

import Flex from '../atomics/Flex'
import Icon, { IIcon } from '../atomics/Icon'

interface IPageInfo {
  pageName: string
  iconTypeList?: Array<IIcon & { onClick: (id?: string) => void }>
}

export default function Header({
  isShowBack = true,
}: {
  isShowBack?: boolean
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { id } = useParams()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleClickArrowBack = () => navigate(-1)
  const handleClickHeader = () => window.location.reload()
  const handleMenuClose = () => setIsMenuOpen(false)

  const { mutate: deleteMoveRequest } = useDeleteMoveRequest({
    id: id!,
    onSuccess: () => navigate('/'),
  })

  const { mutate: deleteLostRequest } = useDeleteLostRequest({
    id: id!,
    onSuccess: () => navigate(-1),
  })

  const getPageInfo = (pathname: string, id: string): IPageInfo => {
    switch (pathname) {
      case '/':
        return {
          pageName: '이동봉사',
          iconTypeList: [
            {
              type: 'ADD',
              color: 'secondary',
              onClick: () => navigate('/move/register'),
            },
          ],
        }
      case '/lost':
        return {
          pageName: '실종동물 찾아요',
          iconTypeList: [
            {
              type: 'ADD',
              color: 'secondary',
              onClick: () => navigate('/lost/register'),
            },
          ],
        }
      case `/lost/${id}`:
        return {
          pageName: '실종동물정보',
          iconTypeList: [
            {
              type: 'EDIT',
              color: 'secondary',
              onClick: (id?: string) => navigate(`/lost/${id}/edit`),
            },
            {
              type: 'DELETE',
              color: 'secondary',
              onClick: () => deleteLostRequest(),
            },
          ],
        }
      case `/lost/${id}/edit`:
        return {
          pageName: '실종동물 수정',
        }
      case '/lost/register':
        return {
          pageName: '실종동물 등록',
        }
      case '/move/register':
        return {
          pageName: '이동봉사등록',
        }
      case `/move/${id}`:
        return {
          pageName: '이동봉사정보',
          iconTypeList: [
            {
              type: 'EDIT',
              color: 'secondary',
              onClick: (id?: string) => navigate(`/move/${id}/edit`),
            },
            {
              type: 'DELETE',
              color: 'secondary',
              onClick: () => deleteMoveRequest(),
            },
          ],
        }
      case '/info':
        return {
          pageName: '설정',
        }
      default:
        return {
          pageName: '퍼넥팅',
          iconTypeList: [
            {
              type: 'ADD',
              color: 'secondary',
              onClick: () => {},
            },
          ],
        }
    }
  }

  const { pageName, iconTypeList } = getPageInfo(pathname, id!)

  return (
    <HeaderContainer>
      {isShowBack && (
        <ArrowBox align={'center'} onClick={handleClickArrowBack}>
          <ArrowBackIcon />
        </ArrowBox>
      )}
      <Flex align={'center'} flex gap={6} onClick={handleClickHeader}>
        {/* <HubIcon fontSize={'small'} color={'secondary'} /> */}
        <Flex className={'pageName'}>{pageName}</Flex>
      </Flex>
      <ButtonBox align={'center'}>
        {iconTypeList &&
          iconTypeList.length > 0 &&
          iconTypeList.map((iconType, index) => (
            <Flex
              key={index}
              className={'icon'}
              align={'center'}
              onClick={() => iconType.onClick(id)}
            >
              <Icon
                type={iconType.type}
                color={iconType.color}
                fontSize={iconType.fontSize}
              />
            </Flex>
          ))}
        <Flex
          className={'icon'}
          align={'center'}
          onClick={() => setIsMenuOpen((p) => !p)}
        >
          <Icon type={'MENU'} />
        </Flex>
        <Drawer
          anchor={'right'}
          open={isMenuOpen}
          onClose={handleMenuClose}
          PaperProps={{
            sx: {
              borderRadius: '10px 0 0 10px',
              minWidth: 'calc(100vw - 60px)',
            },
          }}
        >
          <Info onClose={handleMenuClose} />
        </Drawer>
      </ButtonBox>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.header`
  position: sticky;
  top: 0px;
  display: flex;
  flex: 1;
  max-height: 60px;
  background: #fff;
  min-height: 60px;
  z-index: 100;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);

  .pageName {
    /* color: ${colors.purple[700]}; */
    font-weight: 700;
  }

  .icon_box {
    position: absolute;
    top: 0;
    right: 0;
  }
`

const ArrowBox = styled(Flex)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 60px;
  height: 60px;
`

const ButtonBox = styled(Flex)`
  position: absolute;
  top: 0px;
  right: 16px;
  gap: 4px;

  .icon {
    width: 30px;
    height: 60px;
  }
`
