import { useEffect, useState } from 'react'
import {
  FieldValues,
  Path,
  PathValue,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form'
import styled from 'styled-components/macro'

import Button from '@mui/material/Button'
import { Storage } from 'aws-amplify'

import Icon from 'src/atomics/Icon'

import Flex from '../atomics/Flex'

interface IUploadButtons<T extends FieldValues> {
  id: string
  value?: PathValue<T, Path<T>>
  buttonStyle?: React.CSSProperties
  buttonWrapStyle?: React.CSSProperties
  showButtonName?: boolean
  buttonName?: string
  icon?: React.ReactNode
  isSquare?: boolean
  register?: UseFormRegister<T>
  setValue?: UseFormSetValue<T>
  fieldName?: Path<T>
}

export default function UploadButtons<T extends FieldValues>({
  id,
  value,
  buttonStyle,
  buttonWrapStyle,
  showButtonName = true,
  buttonName = 'Upload',
  icon = <Icon type={'ADD_IMAGE'} fontSize={'large'} />,
  isSquare = false,
  register,
  setValue,
  fieldName = id as Path<T>,
}: IUploadButtons<T>) {
  const [fileImageSrc, setFileImageSrc] = useState(value)

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    if (!files) return
    const file = files && files[0]
    await Storage.put(file.name, file)
    const img = await Storage.get(file.name)
    const url = new URL(img)
    const { origin, pathname } = url
    const imageUrl = (origin + pathname) as PathValue<T, Path<T>>
    setFileImageSrc(imageUrl)
    setValue?.(fieldName, imageUrl)
  }

  useEffect(() => {
    setValue?.(fieldName, value!)
  }, [])

  return (
    <UploadButtonsContainer flex style={buttonWrapStyle}>
      <Button
        variant="outlined"
        component="label"
        color={'inherit'}
        style={{
          color: '#666',
          flex: 1,
          borderRadius: 16,
          padding: 0,
          overflow: 'hidden',
          borderColor: '#666',
          ...buttonStyle,
        }}
      >
        <Flex direction={'column'} align={'center'} gap={4} flex>
          {fileImageSrc ? (
            <StyledImage
              src={fileImageSrc}
              alt={'img'}
              className={isSquare ? 'section__thumbnail' : ''}
            />
          ) : (
            <Flex
              className={'section__upload'}
              align={'center'}
              direction={'column'}
              style={{ width: '100%' }}
            >
              {icon}
              {showButtonName && <Flex>{buttonName}</Flex>}
            </Flex>
          )}
        </Flex>
        <input
          id={id}
          hidden
          accept="image/*"
          type="file"
          onChange={handleChange}
        />
        <input hidden {...register?.(fieldName)} />
      </Button>
    </UploadButtonsContainer>
  )
}

const UploadButtonsContainer = styled(Flex)`
  .section__upload {
    aspect-ratio: auto 1/1;
  }

  .section__thumbnail {
    aspect-ratio: 1/1;
  }
`

const StyledImage = styled.img`
  width: 100%;
`
