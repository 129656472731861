import LabelIcon from '../LabelIcon'

interface IPublicLabelIcon {
  active?: boolean
}

export default function PublicLabelIcon({ active = false }: IPublicLabelIcon) {
  return (
    <LabelIcon
      category={'transportation'}
      type={'PUBLIC'}
      label={'대중교통'}
      active={active}
    />
  )
}
