export const PLACE_INFO: PlaceType = {
  address: '',
  placeName: '',
  location: { lat: 0, lng: 0 },
}

export const LOST_INFO: LostInfo = {
  lostDate: '',
  lostPlace: PLACE_INFO,
}

export const MOVE_INFO: MoveInfo = {
  requestType: 'DOMESTIC',
  transportation: 'CAR',
  tripType: 'ONEWAY',
  startPoint: '',
  endPoint: '',
  date: '',
}

export const REPORT_INFO: ReportInfo = {
  id: '',
  date: '',
  place: {
    address: '',
    placeName: '',
    location: { lat: 0, lng: 0 },
  },
  image: '',
  lostRequestInfoReportListId: '',
}

export const COMPANION_ANIMAL_INFO: companionAnimalInfo = {
  name: '',
  weight: 0,
}

export const LOST_REQUEST_INFO: LostRequestInfo = {
  lostInfo: LOST_INFO,
  companionAnimalInfo: COMPANION_ANIMAL_INFO,
  reportList: { items: [], nextToken: null },
}

export const MOVE_REQUEST_INFO: MoveRequestInfo = {
  moveInfo: MOVE_INFO,
  companionAnimalInfo: COMPANION_ANIMAL_INFO,
}

export interface INITIAL_DATA_TYPE {
  MOVE_REQUEST_INFO: MoveRequestInfo
  LOST_REQUEST_INFO: LostRequestInfo
  REPORT_INFO: ReportInfo
}

export const INITIAL_DATA: INITIAL_DATA_TYPE = {
  MOVE_REQUEST_INFO,
  LOST_REQUEST_INFO,
  REPORT_INFO,
}
