import { useState } from 'react'
import styled from 'styled-components/macro'

import { ListSubheader } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import Flex from 'src/atomics/Flex'

import ADDR from '../utils/data/addr.json'

type AddressDataType = {
  si: string[]
  sido: string[]
  sigungu: {
    [key: string]: string[]
  }
}

interface IAddressSelector {
  onChange?: (address: string) => void
  children: React.ReactNode
}

function AddressSelector({ onChange, children }: IAddressSelector) {
  const [open, setOpen] = useState(false)
  const [address1, setAddress1] = useState<string>('')
  const [address2, setAddress2] = useState<string>('')

  const ADDRESS: AddressDataType = ADDR

  const getHasValue = () => {
    return address1 === '세종특별자치시' || (address1 !== '' && address2 !== '')
  }
  const hasValue = getHasValue()

  const handleChangeAddress1 = (event: SelectChangeEvent) => {
    setAddress1(event.target.value || '')
    setAddress2('')
  }
  const handleChangeAddress2 = (event: SelectChangeEvent) => {
    setAddress2(event.target.value || '')
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (e: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
    setAddress1('')
    setAddress2('')
  }

  const handleSelect = (e: React.SyntheticEvent<unknown>, reason?: string) => {
    const address = address1 + ' ' + address2
    onChange?.(address.trim())
    handleClose(e, reason)
  }

  return (
    <Flex flex>
      <Button onClick={handleClickOpen} disableRipple sx={{ p: 0 }}>
        {children}
      </Button>
      <StyledDialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle color={'secondary'} fontWeight={900}>
          주소 선택
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FormControl sx={{ m: 1, minWidth: 100 }}>
              <InputLabel htmlFor="ialog-select-address1" color={'secondary'}>
                주소1
              </InputLabel>
              <Select
                labelId="dialog-select-label-address1"
                id="dialog-select-address1"
                value={address1}
                onChange={handleChangeAddress1}
                input={<OutlinedInput label="주소1" color={'secondary'} />}
                color={'secondary'}
              >
                <ListSubheader>시</ListSubheader>
                {ADDRESS.si.map((si: string, idx: number) => (
                  <MenuItem key={`si-${idx}`} value={si}>
                    {si}
                  </MenuItem>
                ))}
                <ListSubheader>도</ListSubheader>
                {ADDRESS.sido.map((sido: string, idx: number) => (
                  <MenuItem key={`sido-${idx}`} value={sido}>
                    {sido}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              sx={{ m: 1, minWidth: 100 }}
              disabled={address1 === '' || address1 === '세종특별자치시'}
            >
              <InputLabel id="dialog-select-address2" color={'secondary'}>
                주소2
              </InputLabel>
              <Select
                labelId="dialog-select-label-address2"
                id="dialog-select-address2"
                value={address2}
                onChange={handleChangeAddress2}
                input={<OutlinedInput label="주소2" color={'secondary'} />}
                color={'secondary'}
              >
                {address1 &&
                  ADDRESS.sigungu[address1].map((sigu: string, idx: number) => (
                    <MenuItem key={`sigu-${idx}`} value={sigu}>
                      {sigu}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color={'secondary'}>
            취소
          </Button>
          <Button
            onClick={handleSelect}
            color={'secondary'}
            disabled={!hasValue}
          >
            선택
          </Button>
        </DialogActions>
      </StyledDialog>
    </Flex>
  )
}

export default AddressSelector

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    margin: 20px;
    flex: 1;
  }
`
