import LabelIcon from '../LabelIcon'

interface IDomesticLabelIcon {
  active?: boolean
}

export default function DomesticLabelIcon({
  active = false,
}: IDomesticLabelIcon) {
  return (
    <LabelIcon
      category={'requestType'}
      type={'DOMESTIC'}
      label={'국내'}
      ratio={{ x: 1, y: 0.25 }}
      active={active}
      position={'left'}
      borderRadius={12}
    />
  )
}
