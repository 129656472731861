import styled, { css } from 'styled-components/macro'

import { Divider } from '@mui/material'
import dayjs from 'dayjs'

import Flex from '../../atomics/Flex'
import Icon from '../../atomics/Icon'
import CardStyle from '../../styles/CardStyle'
import Chips from '../Chips'

interface ILostCard {
  lostInfo: LostRequestInfo
  isSelected?: boolean
  onClick?: () => void
}

export default function LostCard({
  lostInfo,
  isSelected = false,
  onClick,
}: ILostCard) {
  const DEFAULT_IMAGE =
    'https://chapteronedogtraining.com.au/wp-content/uploads/2021/08/home-puppy-1-1000.jpg'
  const {
    companionAnimalInfo: { name, age, weight, gender, image },
    lostInfo: { lostDate },
  } = lostInfo

  const datesYYYYmmDD = dayjs(lostDate).format('YYYY.MM.DD')
  const datesHHmm = dayjs(lostDate).format('HH:mm')

  return (
    <LostCardContainer
      flex
      align={'between'}
      isSelected={isSelected}
      onClick={onClick}
    >
      <Flex>
        <img src={image || DEFAULT_IMAGE} alt={'img'} className={'image'} />
      </Flex>
      <Flex direction={'column'} gap={4} flex>
        <Flex align={'centerY'}>
          <Flex className={'info bold'}>{name}</Flex>
        </Flex>
        <Flex align={'centerY'} flex>
          <Flex className={'info'} flex align={'center'}>
            {age || 1}세
          </Flex>
          <Divider sx={{ height: 12, m: 0.5 }} orientation="vertical" />
          <Flex className={'info'} flex align={'center'}>
            {weight || 1}kg
          </Flex>
          <Divider sx={{ height: 12, m: 0.5 }} orientation="vertical" />
          <Flex className={'info'} flex align={'center'}>
            <Icon category={'gender'} type={gender || 'MALE'} />
          </Flex>
        </Flex>

        <Flex align={'centerY'}>
          <Flex className={'info'}>
            <Chips label={'OPEN'} color={'success'} size={'small'} />
          </Flex>
        </Flex>
        <Flex>
          <Flex className={'info'}>{datesYYYYmmDD}</Flex>
          <Divider sx={{ height: 12, m: 1 }} orientation="vertical" />
          <Flex className={'info'}>{datesHHmm}</Flex>
        </Flex>
      </Flex>
    </LostCardContainer>
  )
}

const LostCardContainer = styled(CardStyle)<{ isSelected: boolean }>`
  padding: 16px;
  border: 1px solid #f1f1f1;
  border-radius: 12px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
  cursor: pointer;
  gap: 16px;
  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid #65f256;
    `};

  &:hover {
  }

  .info {
    font-size: 0.9rem;
    color: #333;
  }

  .bold {
    font-weight: 700;
  }

  .image {
    width: 100px;
    height: 100px;
    border-radius: 12px;
    object-fit: cover;
  }
`
