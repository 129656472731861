import styled from 'styled-components/macro'

export type DirectionType = 'column' | 'row' | 'column-reverse' | 'row-reverse'
export type AlignType =
  | 'start'
  | 'end'
  | 'center'
  | 'between'
  | 'around'
  | 'evenly'
  | 'centerX'
  | 'centerY'

export interface IFlex extends React.HTMLAttributes<HTMLDivElement> {
  direction?: DirectionType
  gap?: number
  align?: AlignType
  flex?: boolean
  className?: string
  style?: React.CSSProperties
  children: React.ReactNode
}

export default function Flex({
  direction = 'row',
  gap = 0,
  align,
  flex = false,
  className,
  children,
  style,
  ...restProps
}: IFlex) {
  return (
    <FlexContainer
      direction={direction}
      gap={gap}
      align={align}
      flex={flex}
      className={className}
      style={style}
      {...restProps}
    >
      {children}
    </FlexContainer>
  )
}

const FlexContainer = styled.div<IFlex>`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === 'row' ? 'row' : 'column'};
  gap: ${({ gap }) => `${gap}px`};
  flex: ${({ flex }) => flex && 1};

  ${({ direction, align }) => {
    switch (align) {
      case 'center':
        return `
        justify-content: center;
        align-items: center;
      `
      case 'centerX':
        return direction === 'row'
          ? `justify-content: center;`
          : 'align-items: center;'
      case 'centerY':
        return direction === 'row'
          ? `align-items: center;`
          : 'justify-content: center;'
      case 'between':
        return `justify-content: space-between;`
      case 'start':
        return direction === 'row'
          ? `justify-content: start;`
          : 'align-items: start;'
      case 'end':
        return direction === 'row'
          ? `justify-content: end;`
          : 'align-items: end;'
      case 'evenly':
        return `justify-content: space-evenly;`
      case 'around':
        return `justify-content: space-around;`
      default:
        return ''
    }
  }}
`
