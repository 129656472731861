import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import MoveInfoCard from './MoveInfoCard'
import { useMoveRequestList } from 'src/react-query-hooks/move'

import Flex from '../../atomics/Flex'

export default function MoveCardList() {
  const navigate = useNavigate()
  const { data: moveRequestList } = useMoveRequestList()

  const navigateToMoveRequestDetail = (moveRequestInfoId: string) => {
    navigate(`/move/${moveRequestInfoId}`, { state: { id: moveRequestInfoId } })
  }

  return (
    <MoveCardListContainer flex direction={'column'} gap={10}>
      {moveRequestList.map((moveRequestInfo: MoveRequestInfo, idx: number) => (
        <MoveInfoCard
          key={`moveInfoCard-${idx}`}
          moveRequestInfo={moveRequestInfo}
          onClick={() => navigateToMoveRequestDetail(moveRequestInfo.id!)}
        />
      ))}
    </MoveCardListContainer>
  )
}

const MoveCardListContainer = styled(Flex)`
  margin: 16px;
`
