import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import { DevTool } from '@hookform/devtools'
import { Button, InputAdornment } from '@mui/material'
import { useRecoilValue } from 'recoil'

import Flex from 'src/atomics/Flex'
import Icon from 'src/atomics/Icon'
import Page from 'src/atomics/Page'
import DateTimePickers from 'src/components/DateTimePickers'
import Inputs from 'src/components/Inputs'
import PlaceDrawer from 'src/components/lost/PlaceDrawer'
import UploadButtons from 'src/components/UploadButtons'
import { useLostRegister } from 'src/react-query-hooks/lost'
import { selectedPlaceAtom } from 'src/stores/commonStores'
import GenderRadios from 'src/templates/GenderRadios'

export type LostFormType = {
  date: string
  lostPlace: PlaceType
  contact: string
  name: string
  weight: number
  age: number
  gender: GenderType
  memo: string
  image: string
}

export default function LostRegisterPage() {
  const navigate = useNavigate()
  const [isOnPlaceAdd, setIsOnPlaceAdd] = useState(false)
  const selectedPlace = useRecoilValue(selectedPlaceAtom)

  const { mutate: lostRegister } = useLostRegister({
    isEdit: false,
    onSuccess: () => {
      navigate(-1)
    },
  })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<LostFormType>()
  const onSubmit: SubmitHandler<LostFormType> = (values) => {
    if (selectedPlace) {
      const { x = '0', y = '0' } = selectedPlace
      const {
        date,
        lostPlace: { address, placeName },
        contact,
        name,
        weight,
        image,
        age,
        gender,
        memo,
      } = values
      const registerData: LostRequestInfo = {
        lostInfo: {
          lostDate: date,
          lostPlace: {
            address,
            placeName,
            location: { lat: Number(y), lng: Number(x) },
          },
          contact,
        },
        companionAnimalInfo: { name, weight, image, age, gender, memo },
      }
      lostRegister(registerData)
    }
  }

  return (
    <LostRegisterPageContainer fullX={false}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit(onSubmit)()
        }}
        className={'form__container'}
      >
        <UploadButtons<LostFormType>
          id={'file'}
          fieldName={'image'}
          register={register}
          setValue={setValue}
        />
        <Inputs<LostFormType>
          label={'이름'}
          fieldName={'name'}
          register={register}
          // error={isFocus && validateMsg === 'name'}
        />
        <Flex gap={16}>
          <Inputs<LostFormType>
            label={'나이'}
            fieldName={'age'}
            textAlign={'center'}
            register={register}
          />
          <Inputs<LostFormType>
            label={'몸무게'}
            fieldName={'weight'}
            textAlign={'center'}
            register={register}
            endAdornment={<InputAdornment position="end">kg</InputAdornment>}
          />
        </Flex>
        <GenderRadios<LostFormType> register={register} fieldName={'gender'} />

        <DateTimePickers<LostFormType>
          fieldName={'date'}
          label={'실종시간'}
          // error={validateMsg === 'date'}
          register={register}
        />
        <Inputs<LostFormType>
          label={'실종장소'}
          fieldName={'lostPlace.address'}
          register={register}
          onClick={() => setIsOnPlaceAdd(true)}
          // error={isFocus && validateMsg === 'name'}
        />
        <Inputs<LostFormType>
          showLabel={false}
          label={'상세주소'}
          fieldName={'lostPlace.placeName'}
          register={register}
          onClick={() => setIsOnPlaceAdd(true)}
          // error={isFocus && validateMsg === 'name'}
        />
        <PlaceDrawer
          open={isOnPlaceAdd}
          onAdd={(place) => {
            setValue('lostPlace.address', place.address_name || '')
            setValue('lostPlace.placeName', place.place_name)
          }}
          onClose={() => setIsOnPlaceAdd(false)}
        />
        <Inputs<LostFormType>
          label={'특이사항'}
          fieldName={'memo'}
          register={register}
        />
        <Inputs<LostFormType>
          label={'연락처'}
          fieldName={'contact'}
          register={register}
        />

        <Button type={'submit'}>
          <Icon type={'CHECK'} fontSize={'small'} color={'success'} />
        </Button>
      </form>
      <DevTool control={control} />
    </LostRegisterPageContainer>
  )
}

const LostRegisterPageContainer = styled(Page)`
  .form__container {
    padding: 16px 0px;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 16px;
  }
`
