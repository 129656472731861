import styled from 'styled-components/macro'

import { Modal as MUIModal } from '@mui/material'
import { useRecoilState } from 'recoil'

import { modalAtom } from 'src/stores/modalStores'

export default function Modal() {
  const [modalState, setModalState] = useRecoilState(modalAtom)

  const handleClose = () => setModalState((p) => ({ ...p, show: false }))

  return (
    <MUIModal
      open={modalState.show}
      onClose={handleClose}
      disableEscapeKeyDown={modalState.closeOnBackdropClick}
      sx={modalState.sx}
    >
      <ModalWrapper style={modalState.modalWrapperStyle}>
        {modalState.body}
      </ModalWrapper>
    </MUIModal>
  )
}

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
`
