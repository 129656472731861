import React from 'react'

import Flex from 'src/atomics/Flex'
import Page from 'src/atomics/Page'
import KakaoMap from 'src/components/KakaoMap'
import Location from 'src/components/Location'
import MoveCardList from 'src/components/move/MoveCardList'
import IconTabs from 'src/components/Tab'

export default function MoveListPage() {
  return (
    <Page>
      <Flex flex direction={'column'}>
        {/* <SearchBar />
      <FilterBar /> */}
        <KakaoMap />
        <IconTabs />
        <Location />
        <MoveCardList />
      </Flex>
    </Page>
  )
}
