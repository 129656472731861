import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

import {
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import {
  Map,
  MapMarker,
  MapTypeControl,
  MarkerClusterer,
} from 'react-kakao-maps-sdk'
import { useRecoilState, useSetRecoilState } from 'recoil'

import CustomMarker from './CustomMarker'
import CustomOverlay from './CustomOverlay'
import PositionMarker from './PositionMarker'
import SearchBar from './SearchBar'
import Flex from 'src/atomics/Flex'
import Icon from 'src/atomics/Icon'
import {
  currentReportInfoAtom,
  isShowPositionMarkerAtom,
  placeSearchListAtom,
  selectedPlaceAtom,
} from 'src/stores/commonStores'
import { centerPositionAtom } from 'src/stores/mapStores'
import { lostRequestListInfoAtom } from 'src/stores/requestStores'
import { getPosToFixedDecimal } from 'src/utils/number'

import useGeoLocation from '../hooks/useGeoLocation'

type ICoordsSearchResult = {
  address: kakao.maps.services.Address
  road_address: kakao.maps.services.RoadAaddress | null
}
export interface IPlaceSearchResult
  extends kakao.maps.services.PlacesSearchResultItem,
    ICoordsSearchResult {
  isSelected?: boolean
}

const ZOOM_LEVEL_DEFAULT = 9
const FIXED_DECIMAL_3 = 3
const FIXED_DECIMAL_2 = 2

function KakaoMap({
  search = false,
  positionMarker = false,
  reportMarker = false,
  reportList = [],
  placeList = [],
  onSelect,
}: {
  search?: boolean
  positionMarker?: boolean
  reportMarker?: boolean
  reportList?: ReportInfo[]
  placeList?: LostRequestInfo[]
  onSelect?: (place: Partial<IPlaceSearchResult>) => void
}) {
  const { pos } = useGeoLocation()
  const { lat, lng } = pos

  const [position, setPosition] = useState({ lat, lng })
  const [centerPosition, setCenterPosition] = useRecoilState(centerPositionAtom)
  const [isSearchListCollapseOpen, setIsSearchListCollapseOpen] =
    useState(false)
  const [isShowPositionMarker, setIsShowPositionMarker] = useRecoilState(
    isShowPositionMarkerAtom
  )
  const setLostRequestListInfo = useSetRecoilState(lostRequestListInfoAtom)
  const searchListCollapseRef = useRef<HTMLDivElement>(null)

  const mapRef = useRef<kakao.maps.Map>(null)

  const [keyword, setKeyword] = useState('')
  const [placeSearchList, setPlaceSearchList] =
    useRecoilState<Array<Partial<IPlaceSearchResult>>>(placeSearchListAtom)
  const setSelectedPlace = useSetRecoilState(selectedPlaceAtom)
  const [currentReport, setCurrentReport] = useRecoilState(
    currentReportInfoAtom
  )

  const polyline = new window.kakao.maps.Polyline({
    path: [
      new window.kakao.maps.LatLng(lng, lat),
      new window.kakao.maps.LatLng(127, 36),
    ],
  })

  const handleClickMap = (
    target: kakao.maps.Map,
    mouseEvent: kakao.maps.event.MouseEvent
  ) => {
    if (!positionMarker) return
    const lat = mouseEvent.latLng.getLat()
    const lng = mouseEvent.latLng.getLng()
    setPosition({ lat, lng })
    setIsShowPositionMarker(true)
    setPlaceSearchList((placeSearchList) =>
      placeSearchList.map((place) => ({ ...place, isSelected: false }))
    )
    setCenterPosition({ lat, lng })
  }

  const handleClickPlace = ({ pos, id }: { pos: PosType; id?: string }) => {
    const { lat, lng } = pos

    setCenterPosition({ lat, lng })
    setPlaceSearchList((placeSearchList) =>
      placeSearchList.map((place) => ({
        ...place,
        isSelected: place.id === id,
      }))
    )
  }

  const handleClickSinglePlace = (pos: PosType) => {
    const { lat, lng } = pos
    const ps = new kakao.maps.services.Geocoder()
    ps.coord2Address(lng, lat, (result, status) => {
      if (status === kakao.maps.services.Status.OK) {
        setSelectedPlace(() => ({
          address_name: result[0].address.address_name,
          x: String(lng),
          y: String(lat),
          isSelected: true,
        }))
      }
    })
  }

  const handleSearch = () => {
    const callback = (
      result: kakao.maps.services.PlacesSearchResult,
      status: kakao.maps.services.Status,
      _pagination: kakao.maps.Pagination
    ) => {
      if (status === kakao.maps.services.Status.OK) {
        const bounds = new kakao.maps.LatLngBounds()

        for (let i = 0; i < result.length; i++) {
          // @ts-ignore
          bounds.extend(
            new kakao.maps.LatLng(Number(result[i].y), Number(result[i].x))
          )
        }

        mapRef.current?.setBounds(bounds)
        const placeSearchList = result.map((item) => ({
          ...item,
          isSelected: false,
        }))
        setPlaceSearchList(placeSearchList)
        setIsSearchListCollapseOpen(true)
        setIsShowPositionMarker(false)
      }
    }
    const options: kakao.maps.services.PlacesSearchOptions = {
      location: new window.kakao.maps.LatLng(
        centerPosition.lat,
        centerPosition.lng
      ),
      sort: kakao.maps.services.SortBy.DISTANCE,
    }
    const ps = new kakao.maps.services.Places()
    ps.keywordSearch(keyword, callback, options)
  }

  const level = mapRef.current?.getLevel()

  const DECIMAL_PLACE =
    level ?? ZOOM_LEVEL_DEFAULT > ZOOM_LEVEL_DEFAULT - 1
      ? FIXED_DECIMAL_3
      : FIXED_DECIMAL_2

  const { lat: initialLat, lng: initialLng } = getPosToFixedDecimal({
    lat,
    lng,
    decimalPlace: DECIMAL_PLACE,
  })
  const { lat: currentLat, lng: currentLng } = getPosToFixedDecimal({
    lat: centerPosition.lat,
    lng: centerPosition.lng,
    decimalPlace: DECIMAL_PLACE,
  })

  const isCurrentLocation =
    initialLat === currentLat && initialLng === currentLng

  useEffect(() => {
    if (mapRef.current && !reportMarker) {
      mapRef.current.setCenter(new kakao.maps.LatLng(lat, lng))
    }
  }, [lat, lng])

  useEffect(() => {
    if (!reportMarker) return
    if (mapRef.current && reportList.length > 0) {
      const bounds = new kakao.maps.LatLngBounds()

      for (let i = 0; i < reportList.length; i++) {
        const { lat, lng } = reportList[i].place.location
        // @ts-ignore
        bounds.extend(new kakao.maps.LatLng(lat, lng))
      }

      mapRef.current.setBounds(bounds)
    }
  }, [reportList, mapRef.current])

  return (
    <>
      <MapContainer direction={'column'}>
        <Map
          center={{ lat: centerPosition.lat, lng: centerPosition.lng }}
          className={'map'}
          level={ZOOM_LEVEL_DEFAULT}
          onClick={(target, mouseEvent) => handleClickMap(target, mouseEvent)}
          isPanto
          onIdle={(target) => {
            setCenterPosition({
              lat: target.getCenter().getLat(),
              lng: target.getCenter().getLng(),
            })
          }}
          ref={mapRef}
        >
          {isCurrentLocation && (
            <MapMarker
              position={{ lat, lng }}
              image={{
                src: '/assets/svg/dot.svg',
                size: { width: 18, height: 18 },
              }}
            />
          )}
          <MarkerClusterer
            averageCenter={true} // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정
            minLevel={ZOOM_LEVEL_DEFAULT - 1} // 클러스터 할 최소 지도 레벨
          >
            {placeSearchList.length > 0 &&
              placeSearchList.map((place) => {
                const { x, y, isSelected, id } = place
                const lat = Number(y)
                const lng = Number(x)
                return (
                  <CustomMarker
                    key={`${place.x}-${place.y}`}
                    pos={{ lat, lng }}
                    isSelected={isSelected}
                    onClick={() => {
                      handleClickPlace({ pos: { lat, lng }, id })
                      const selectedPlace = placeSearchList.find(
                        (place) => place.id === id
                      )
                      selectedPlace && onSelect?.(selectedPlace)
                      setIsSearchListCollapseOpen(false)
                      setIsShowPositionMarker(false)
                    }}
                  />
                )
              })}
          </MarkerClusterer>

          <MarkerClusterer
            averageCenter={true} // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정
            minLevel={ZOOM_LEVEL_DEFAULT - 1} // 클러스터 할 최소 지도 레벨
          >
            {placeList.length > 0 &&
              placeList.map((place) => {
                const { lat, lng } = place.lostInfo.lostPlace.location
                return (
                  <CustomMarker
                    key={place.id}
                    pos={{ lat, lng }}
                    isOnAdd={false}
                    isSelected={place.isSelected}
                    onClick={() => {
                      setLostRequestListInfo((infoList) =>
                        infoList.map((info) => ({
                          ...info,
                          isSelected: info.id === place.id,
                        }))
                      )
                      setCenterPosition({ lat, lng })
                    }}
                  />
                )
              })}
          </MarkerClusterer>

          {positionMarker && isShowPositionMarker && (
            <PositionMarker
              position={position}
              onSelect={() =>
                handleClickSinglePlace({
                  lat: centerPosition.lat,
                  lng: centerPosition.lng,
                })
              }
            />
          )}

          {reportMarker && (
            <MarkerClusterer
              averageCenter={true} // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정
              minLevel={ZOOM_LEVEL_DEFAULT + 5} // 클러스터 할 최소 지도 레벨
            >
              {reportList.length > 0 &&
                reportList.map((report, index) => {
                  const {
                    id,
                    place: {
                      location: { lat: y, lng: x },
                    },
                  } = report
                  const lat = Number(y)
                  const lng = Number(x)

                  return (
                    <CustomOverlay
                      key={id}
                      pos={{ lat, lng }}
                      onClick={() => {
                        setCurrentReport(report)
                        setCenterPosition({ lat, lng })
                      }}
                      isSelected={report?.id === currentReport?.id}
                    >
                      {index + 1}
                    </CustomOverlay>
                  )
                })}
            </MarkerClusterer>
          )}
          <MapTypeControl position={kakao.maps.ControlPosition.TOPRIGHT} />
          <Flex
            className={'current__location__box'}
            onClick={() => setCenterPosition({ lat, lng })}
          >
            <Icon
              type={isCurrentLocation ? 'GPS_FIXED_ICON' : 'GPS_NOT_FIXED_ICON'}
              color={isCurrentLocation ? 'info' : 'action'}
            />
          </Flex>
        </Map>
      </MapContainer>
      {search && (
        <>
          <SearchBarContainer flex>
            <SearchBar
              portalStatus={true}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onSearch={handleSearch}
            />
            <Button
              onClick={() => setIsSearchListCollapseOpen((p) => !p)}
              disabled={!Boolean(placeSearchList.length > 0)}
            >
              {isSearchListCollapseOpen ? (
                <Icon type={'EXPAND_MORE_ICON'} className={'collapse__icon'} />
              ) : (
                <Icon
                  type={'EXPAND_MORE_ICON'}
                  className={'collapse__icon close'}
                />
              )}
            </Button>
          </SearchBarContainer>

          <SearchListContainer
            in={isSearchListCollapseOpen}
            ref={searchListCollapseRef}
            sx={{ width: '100%' }}
          >
            <List sx={{ width: '100%', background: '#fff' }}>
              {placeSearchList.length > 0 &&
                placeSearchList.map((place) => {
                  const {
                    id,
                    address_name,
                    road_address_name,
                    place_url,
                    place_name,
                    x,
                    y,
                    isSelected,
                  } = place
                  const lat = Number(y)
                  const lng = Number(x)
                  const labelId = `list-label-${address_name}`
                  return (
                    <React.Fragment key={`${id}-${x}-${y}`}>
                      <ListItem disablePadding sx={{ minHeight: 60.03 }}>
                        <ListItemButton role={undefined} dense>
                          <ListItemIcon
                            onClick={
                              isSelected
                                ? () => {
                                    onSelect?.(place)
                                    setIsSearchListCollapseOpen(false)
                                  }
                                : () =>
                                    handleClickPlace({
                                      pos: { lat, lng },
                                      id,
                                    })
                            }
                          >
                            <img
                              src={
                                isSelected
                                  ? '/assets/svg/map_marker_p_add.svg'
                                  : '/assets/svg/map_marker_b.svg'
                              }
                              alt={'marker'}
                              width={40}
                              height={40}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={place_name}
                            secondary={road_address_name}
                            onClick={() => {
                              handleClickPlace({ pos: { lat, lng }, id })
                              const bounds = new kakao.maps.LatLngBounds()
                              bounds.extend(new kakao.maps.LatLng(lat, lng))
                              mapRef.current?.setBounds(bounds)
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider variant="middle" component="li" />
                    </React.Fragment>
                  )
                })}
            </List>
          </SearchListContainer>
        </>
      )}
    </>
  )
}

export default KakaoMap

const MapContainer = styled(Flex)`
  position: relative;
  aspect-ratio: 1 / 0.6;

  .map {
    aspect-ratio: 1 / 0.6;
  }

  .custom__overlay {
    display: flex;
    border: 1px solid red;
    width: 52px;
    /* padding: 16px;
    
    /* border-radius: 16px;
    /* background: #fff;
    box-shadow: 0 3px 10px rgb(0 0 0 / 10%); */
    color: #333;
  }

  .current__location__box {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 10;
    background: #fff;
    border-radius: 50%;
    padding: 8px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
  }
`

const SearchBarContainer = styled(Flex)`
  background: #fff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);

  .collapse__icon {
    transition: all ease-in-out 0.2s;

    &.close {
      transform: rotate(180deg);
    }
  }
`

const SearchListContainer = styled(Collapse)`
  max-height: calc(100vh - 430px);
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
  overflow: auto;
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 0px 0px 16px 16px;
`
