import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Button, Divider, colors } from '@mui/material'

import { useMoveRequestDetail } from 'src/react-query-hooks/move'

import Flex from '../../atomics/Flex'
import Icon from '../../atomics/Icon'
import Image from '../../atomics/Image'
import Page from '../../atomics/Page'
import DetailPanel from '../../components/DetailPanel'
import LocationCard from '../../components/LocationCard'

enum TripTypeEnum {
  'ONEWAY' = '단방향',
  'ROUND' = '왕복',
}

export default function MoveDetailPage() {
  const navigate = useNavigate()
  const location = useLocation()

  const moveDetailId = location.state.id
  const onEdit = () => {
    navigate('/register', { preventScrollReset: true })
  }

  const { data: moveRequestDetail } = useMoveRequestDetail(moveDetailId)
  const { moveInfo, companionAnimalInfo } = moveRequestDetail
  const { requestType, transportation, tripType, startPoint, endPoint, date } =
    moveInfo

  const { name, age, gender, breed, weight, image, memo } = companionAnimalInfo
  const displayMemo = memo ? memo.split('\n') : []

  return (
    <MoveDetailPageContainer>
      <Flex flex direction={'column'} style={{ padding: `0px 16px` }}>
        <Flex
          style={{ padding: '16px 0px', fontSize: '1.3rem', fontWeight: 700 }}
        >
          강아지 정보
        </Flex>
        <Flex
          direction={'column'}
          style={{
            border: '1px solid #f7f7f7',
            borderRadius: 12,
          }}
          gap={16}
        >
          {image && (
            <Image src={image} alt={'img'} style={{ borderRadius: 12 }} />
          )}
        </Flex>
        <Flex align={'evenly'} flex gap={4} className={'box dogInfo'}>
          <Flex align={'center'} className={'column'} direction={'column'}>
            <Flex className={'title'}>이름</Flex>
            <Flex className={'text'}>{name}</Flex>
          </Flex>
          <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
          <Flex align={'center'} className={'column'} direction={'column'}>
            <Flex className={'title'}>견종</Flex>
            <Flex className={'text'}>{breed}</Flex>
          </Flex>
          <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
          <Flex align={'center'} className={'column'} direction={'column'}>
            <Flex className={'title'}>나이</Flex>
            <Flex className={'text'}>{age}세</Flex>
          </Flex>
          <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
          <Flex align={'center'} className={'column'} direction={'column'}>
            <Flex className={'title'}>성별</Flex>
            <Icon category={'gender'} type={gender || 'MALE'} />
          </Flex>
          <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
          <Flex align={'center'} className={'column'} direction={'column'}>
            <Flex className={'title'}>몸무게</Flex>
            <Flex className={'text'}>{weight}kg</Flex>
          </Flex>
        </Flex>
        <DetailPanel title={'이동 장소'} condition={Boolean(startPoint)}>
          <Flex align={'center'} gap={4} flex>
            <LocationCard>{startPoint}</LocationCard>
            <ArrowForwardIcon />
            <LocationCard>{endPoint}</LocationCard>
          </Flex>
        </DetailPanel>
        <Flex direction={'column'} gap={8}>
          <DetailPanel title={'이동 날짜'} condition={Boolean(date)}>
            <Flex className={'box'} direction={'column'} gap={8}>
              <Flex className={'box'}>
                {Boolean(date) &&
                  new Intl.DateTimeFormat('ko', {
                    dateStyle: 'full',
                    timeStyle: 'long',
                  })
                    .format(new Date(date))
                    .split(' ')
                    .slice(0, -2)
                    .join(' ')}
              </Flex>
            </Flex>
          </DetailPanel>
          <DetailPanel title={'이동 수단'} condition={Boolean(true)}>
            <Flex className={'box'} direction={'column'} gap={8}>
              <div>차: 2시간</div>
              <div>기차: 1시간 30분</div>
            </Flex>
          </DetailPanel>
          <DetailPanel title={'메모'} condition={Boolean(memo)}>
            <Flex className={'box memo'} direction={'column'} gap={8}>
              {displayMemo?.map((memo: string, idx: number) => (
                <Flex key={`memo-${idx}`}>{memo}</Flex>
              ))}
            </Flex>
          </DetailPanel>
        </Flex>
        <Flex direction={'column'}>
          <Button
            variant="contained"
            style={{ margin: '16px 0px', borderRadius: 12 }}
            color={'secondary'}
          >
            제가 데려다 줄게요
          </Button>
        </Flex>
      </Flex>
    </MoveDetailPageContainer>
  )
}

const MoveDetailPageContainer = styled(Page)`
  .box {
    background: #f1f1f1;
    border-radius: 16px;
    padding: 16px;
  }

  .title {
    color: ${colors.purple[700]};
    font-weight: 700;
  }

  .dogInfo {
    padding: 16px;
    margin-top: 16px;
  }

  .memo {
    overflow-y: auto;
    max-height: 200px;
  }
`
