import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { API, graphqlOperation } from 'aws-amplify'

import { listMoveRequestInfos } from './../graphql/queries'
import {
  createMoveRequestInfo,
  deleteMoveRequestInfo,
  updateMoveRequestInfo,
} from 'src/graphql/mutations'
import { getMoveRequestInfo } from 'src/graphql/queries'
import { INITIAL_DATA } from 'src/react-query/constant'

const MOVE_REQUEST_KEYS = {
  all: ['MOVE_REQUEST'],
  lists: () => [...MOVE_REQUEST_KEYS.all, 'list'],
  list: (filters?: string) => [...MOVE_REQUEST_KEYS.lists(), { filters }],
  details: () => [...MOVE_REQUEST_KEYS.all, 'detail'],
  detail: (id: string) => [...MOVE_REQUEST_KEYS.details(), id],
}

// createFn
async function createMoveRequest(registerInfo: MoveRequestInfo) {
  await API.graphql(
    graphqlOperation(createMoveRequestInfo, { input: registerInfo })
  )
}

// updateFn
async function editMoveRequest(updateInfo: MoveRequestInfo) {
  await API.graphql(
    graphqlOperation(updateMoveRequestInfo, { input: updateInfo })
  )
}

// listFn
async function listMoveRequest(): Promise<Array<MoveRequestInfo>> {
  const {
    data: {
      listMoveRequestInfos: { items },
    },
  }: any = await API.graphql(graphqlOperation(listMoveRequestInfos))
  return items
}

// detailFn
async function getMoveRequest(id: string): Promise<MoveRequestInfo> {
  const { data }: any = await API.graphql(
    graphqlOperation(getMoveRequestInfo, { id })
  )
  return data.getMoveRequestInfo
}

// deleteFn
export async function deleteMoveRequest(id: string) {
  await API.graphql(graphqlOperation(deleteMoveRequestInfo, { input: { id } }))
}

// registerHook
export function useMoveRequest({
  isEdit,
  onSuccess,
}: {
  isEdit: boolean
  onSuccess?: () => void
}) {
  const queryClient = useQueryClient()
  return useMutation(
    isEdit
      ? (editInfo: MoveRequestInfo) => editMoveRequest(editInfo)
      : (editInfo: MoveRequestInfo) => createMoveRequest(editInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MOVE_REQUEST_KEYS.lists())
        onSuccess?.()
      },
    }
  )
}

// getListHook
export function useMoveRequestList() {
  const fallback: [] = []
  const { data = fallback, ...restFn } = useQuery(
    [MOVE_REQUEST_KEYS.lists()],
    listMoveRequest
  )
  return { data, ...restFn }
}

// getDetailHook
export function useMoveRequestDetail(
  id: string,
  onSuccess?: (data: MoveRequestInfo) => void
) {
  const fallback: MoveRequestInfo = INITIAL_DATA.MOVE_REQUEST_INFO
  const { data = fallback, ...restFn } = useQuery(
    MOVE_REQUEST_KEYS.detail(id),
    () => getMoveRequest(id),
    {
      onSuccess: (res) => {
        onSuccess?.(res)
      },
    }
  )

  return { data, ...restFn }
}

export function useDeleteMoveRequest({
  id,
  onSuccess,
}: {
  id: string
  onSuccess?: () => void
}) {
  const queryClient = useQueryClient()
  return useMutation(() => deleteMoveRequest(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(MOVE_REQUEST_KEYS.lists())
      onSuccess?.()
    },
  })
}
