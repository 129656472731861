import CardStyle from '../styles/CardStyle'

export default function LocationCard({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <CardStyle flex align={'center'}>
      {children}
    </CardStyle>
  )
}
