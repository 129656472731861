import React, { useEffect, useRef } from 'react'

import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import { useSetRecoilState } from 'recoil'

import Icon from 'src/atomics/Icon'
import { searchBarInputDomAtom } from 'src/stores/commonStores'

interface ISearchBar {
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  onSearch?: () => void
  autoFocus?: boolean
  portalStatus?: boolean
}

function SearchBar({
  portalStatus = false,
  value = '',
  onChange,
  placeholder = '장소·주소 검색',
  onSearch,
  autoFocus = false,
  ...restProps
}: ISearchBar) {
  const searchBarRef = useRef<HTMLInputElement | null>(null)
  const mountRef = useRef(false)
  const setSearchBarDomState = useSetRecoilState(searchBarInputDomAtom)

  useEffect(() => {
    if (searchBarRef.current) {
      setSearchBarDomState(searchBarRef.current)
    }

    return () => {
      mountRef.current = false
    }
  }, [])

  return (
    <Paper
      component="form"
      sx={{
        p: '2px 4px 2px 12px',
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        boxShadow: 'unset',
        border: '1px solid #555',
        m: 1,
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.nativeEvent.isComposing) return
          if (e.key === 'Enter') {
            e.preventDefault()
            onSearch?.()
          }
        }}
        autoFocus={autoFocus}
        inputRef={searchBarRef}
        // endAdornment={<div onClick={() => {}}>x</div>}
        endAdornment={
          <IconButton
            type="button"
            sx={{ p: '10px' }}
            aria-label="search"
            onClick={onSearch}
            disabled={value === ''}
          >
            <Icon type={'SEARCH'} />
          </IconButton>
        }
        {...restProps}
      />
    </Paper>
  )
}

export default SearchBar
