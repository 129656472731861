export const getFixedDecimal = (number: number, unit: number) => {
  return Math.floor(number * unit) / unit
}

export const getPosToFixedDecimal = ({
  lat,
  lng,
  decimalPlace,
}: {
  lat: number
  lng: number
  decimalPlace: number
}) => {
  const unit = 10 ** decimalPlace
  const _lat = getFixedDecimal(lat, unit)
  const _lng = getFixedDecimal(lng, unit)

  return { lat: _lat, lng: _lng }
}
