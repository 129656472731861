import React, { useState } from 'react'
import styled from 'styled-components/macro'

import Flex from 'src/atomics/Flex'
import Icon from 'src/atomics/Icon'

function ToggleBar({
  value = false,
  onToggle,
}: {
  value?: boolean
  onToggle?: (open: boolean) => void
}) {
  const [open, setOpen] = useState(value)

  const handleOpen = () => {
    setOpen((p) => !p)
    onToggle?.(!open)
  }

  return (
    <ToggleBarContainer onClick={handleOpen} align={'centerX'}>
      {open ? (
        <Icon type={'ARROW_UP'} color={'action'} />
      ) : (
        <Icon type={'ARROW_DOWN'} color={'action'} />
      )}
    </ToggleBarContainer>
  )
}

export default ToggleBar

const ToggleBarContainer = styled(Flex)`
  background: #fff;
`
