import styled from 'styled-components/macro'

import Flex, { DirectionType, IFlex } from './Flex'

interface IWrapper extends IFlex {
  direction?: DirectionType
}

export default function Wrapper({
  direction,
  children,
  ...restProps
}: IWrapper) {
  return (
    <WrapperContainer direction={direction} {...restProps}>
      {children}
    </WrapperContainer>
  )
}

const WrapperContainer = styled(Flex)`
  flex: 1;
`
