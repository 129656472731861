import { FieldValues, UseFormRegister } from 'react-hook-form'

import FormControlLabel from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import Radio from '@mui/material/Radio'

import OnewayLabelIcon from '../components/icons/LabelIcons/OnewayLabelIcon'
import RoundLabelIcon from '../components/icons/LabelIcons/RoundLabelIcon'
import Radios, { IRadios } from '../components/Radios'

interface ITripTypeRadios extends IRadios {
  value?: TripType
  fieldName?: string
  register?: UseFormRegister<FieldValues>
}

export default function TripTypeRadios({
  value = 'ONEWAY',
  fieldName = 'tripType',
  register,
  ...restProps
}: ITripTypeRadios) {
  return (
    <Radios
      label={
        <InputLabel shrink color={'secondary'}>
          {'이동타입'}
        </InputLabel>
      }
      defaultValue={value}
      {...restProps}
    >
      <FormControlLabel
        value="ONEWAY"
        control={
          <Radio
            disableRipple
            checkedIcon={<OnewayLabelIcon active />}
            icon={<OnewayLabelIcon />}
            sx={{ flex: 1, p: 0 }}
          />
        }
        {...register?.(fieldName)}
        label="Oneway"
        sx={{ m: 0, flex: 1 }}
      />
      <FormControlLabel
        value="ROUND"
        control={
          <Radio
            disableRipple
            checkedIcon={<RoundLabelIcon active />}
            icon={<RoundLabelIcon />}
            sx={{ flex: 1, p: 0 }}
          />
        }
        {...register?.(fieldName)}
        label="Round"
        sx={{ m: 0, flex: 1 }}
      />
    </Radios>
  )
}
