import { FieldValues, UseFormRegister } from 'react-hook-form'

import FormControlLabel from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import Radio from '@mui/material/Radio'

import AirLabelIcon from '../components/icons/LabelIcons/AirLabelIcon'
import CarLabelIcon from '../components/icons/LabelIcons/CarLabelIcon'
import PublicLabelIcon from '../components/icons/LabelIcons/PublicLabelIcon'
import RailLabelIcon from '../components/icons/LabelIcons/RailLabelIcon'
import Radios, { IRadios } from '../components/Radios'

interface ITransportationRadios extends IRadios {
  value?: TransportationType
  fieldName?: string
  register?: UseFormRegister<FieldValues>
}

export default function TransportationRadios({
  value = 'CAR',
  fieldName = 'transportation',
  register,
  ...restProps
}: ITransportationRadios) {
  return (
    <Radios
      label={
        <InputLabel shrink color={'secondary'}>
          {'이동수단'}
        </InputLabel>
      }
      defaultValue={value}
      gap={8}
      {...restProps}
    >
      <FormControlLabel
        value="CAR"
        control={
          <Radio
            disableRipple
            checkedIcon={<CarLabelIcon active />}
            icon={<CarLabelIcon />}
            sx={{ flex: 1, p: 0 }}
          />
        }
        {...register?.(fieldName)}
        label="Car"
        sx={{ m: 0, flex: 1 }}
      />
      <FormControlLabel
        value="RAIL"
        control={
          <Radio
            disableRipple
            checkedIcon={<RailLabelIcon active />}
            icon={<RailLabelIcon />}
            sx={{ flex: 1, p: 0 }}
          />
        }
        {...register?.(fieldName)}
        label="Rail"
        sx={{ m: 0, flex: 1 }}
      />
      <FormControlLabel
        value="AIR"
        control={
          <Radio
            disableRipple
            checkedIcon={<AirLabelIcon active />}
            icon={<AirLabelIcon />}
            sx={{ flex: 1, p: 0 }}
          />
        }
        {...register?.(fieldName)}
        label="Air"
        sx={{ m: 0, flex: 1 }}
      />
      <FormControlLabel
        value="PUBLIC"
        control={
          <Radio
            disableRipple
            checkedIcon={<PublicLabelIcon active />}
            icon={<PublicLabelIcon />}
            sx={{ flex: 1, p: 0 }}
          />
        }
        {...register?.(fieldName)}
        label="Public"
        sx={{ m: 0, flex: 1 }}
      />
    </Radios>
  )
}
