import React, { FC } from 'react'

import CustomMarker from './CustomMarker'

interface IPositionMarker {
  position: PosType
  onSelect?: () => void
}

const PositionMarker: FC<IPositionMarker> = ({
  position,
  onSelect = () => {},
}) => {
  return <CustomMarker pos={position} onClick={onSelect} isSelected />
}

export default PositionMarker
