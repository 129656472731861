import { FieldValues, UseFormRegister } from 'react-hook-form'

import FormControlLabel from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import Radio from '@mui/material/Radio'

import AbroadLabelIcon from '../components/icons/LabelIcons/AbroadLabelIcon'
import DomesticLabelIcon from '../components/icons/LabelIcons/DomesticLabelIcon'
import Radios, { IRadios } from '../components/Radios'

interface IRequestTypeRadios extends IRadios {
  value?: RequestType
  fieldName?: string
  register?: UseFormRegister<FieldValues>
}

export default function RequestTypeRadios({
  value = 'DOMESTIC',
  fieldName = 'requestType',
  register,
  ...restProps
}: IRequestTypeRadios) {
  return (
    <Radios
      label={
        <InputLabel shrink color={'secondary'}>
          {'봉사신청'}
        </InputLabel>
      }
      defaultValue={value}
      {...restProps}
    >
      <FormControlLabel
        value="DOMESTIC"
        control={
          <Radio
            disableRipple
            checkedIcon={<DomesticLabelIcon active />}
            icon={<DomesticLabelIcon />}
            sx={{ flex: 1, p: 0 }}
          />
        }
        {...register?.(fieldName)}
        label="Domestic"
        sx={{ m: 0, flex: 1 }}
      />
      <FormControlLabel
        value="ABROAD"
        control={
          <Radio
            disableRipple
            checkedIcon={<AbroadLabelIcon active />}
            icon={<AbroadLabelIcon />}
            sx={{ flex: 1, p: 0 }}
          />
        }
        {...register?.(fieldName)}
        label="Abroad"
        sx={{ m: 0, flex: 1 }}
      />
    </Radios>
  )
}
