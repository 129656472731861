import { useEffect } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { API, graphqlOperation } from 'aws-amplify'
import { useRecoilState } from 'recoil'

import { INITIAL_DATA } from './../react-query/constant'
import { lostRequestListInfoAtom } from './../stores/requestStores'
import {
  createLostRequestInfo,
  deleteLostRequestInfo,
  updateLostRequestInfo,
} from 'src/graphql/mutations'
import { getLostRequestInfo, listLostRequestInfos } from 'src/graphql/queries'

export const LOST_REQUEST_KEYS = {
  all: ['LOST_REQUEST'],
  lists: () => [...LOST_REQUEST_KEYS.all, 'list'],
  list: (filters?: string) => [...LOST_REQUEST_KEYS.lists(), { filters }],
  details: () => [...LOST_REQUEST_KEYS.all, 'detail'],
  detail: (id: string) => [...LOST_REQUEST_KEYS.details(), id],
}

// createFn
async function createLostRegister(registerInfo: LostRequestInfo) {
  await API.graphql(
    graphqlOperation(createLostRequestInfo, { input: registerInfo })
  )
}

// updateFn
async function updateLostRegister(updateInfo: LostRequestInfo) {
  await API.graphql(
    graphqlOperation(updateLostRequestInfo, { input: updateInfo })
  )
}

// listFn
async function listLostRequest(): Promise<Array<LostRequestInfo>> {
  const {
    data: {
      listLostRequestInfos: { items },
    },
  }: any = await API.graphql(graphqlOperation(listLostRequestInfos))
  return items
}

// detailFn
async function getLostRequest(id: string): Promise<LostRequestInfo> {
  const { data }: any = await API.graphql(
    graphqlOperation(getLostRequestInfo, { id })
  )
  return data.getLostRequestInfo
}

// deleteFn
async function deleteLostRequest(id: string) {
  await API.graphql(graphqlOperation(deleteLostRequestInfo, { input: { id } }))
}

// registerHook
export function useLostRegister({
  isEdit,
  onSuccess,
}: {
  isEdit: boolean
  onSuccess?: () => void
}) {
  const queryClient = useQueryClient()
  return useMutation(
    isEdit
      ? (updateInfo: LostRequestInfo) => updateLostRegister(updateInfo)
      : (registerInfo: LostRequestInfo) => createLostRegister(registerInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          isEdit ? LOST_REQUEST_KEYS.details() : LOST_REQUEST_KEYS.lists()
        )
        onSuccess?.()
      },
    }
  )
}

// getListHook
export function useLostRequestList() {
  const fallback: [] = []
  const { data = fallback, ...restFn } = useQuery(
    [LOST_REQUEST_KEYS.lists()],
    listLostRequest
  )
  const [lostRequestListInfo, setLostRequestListInfo] = useRecoilState(
    lostRequestListInfoAtom
  )

  useEffect(() => {
    setLostRequestListInfo(data.map((v) => ({ ...v, isSelected: false })))
  }, [restFn.isLoading])

  return { data: lostRequestListInfo, ...restFn }
}

// getDetailHook
export function useLostRequestDetail(
  id: string,
  onSuccess?: (data: LostRequestInfo) => void
) {
  const fallback: LostRequestInfo = INITIAL_DATA.LOST_REQUEST_INFO

  const { data = fallback, ...restFn } = useQuery(
    LOST_REQUEST_KEYS.detail(id),
    () => getLostRequest(id),
    {
      placeholderData: INITIAL_DATA.LOST_REQUEST_INFO,
      onSuccess: (res) => {
        onSuccess?.(res)
      },
    }
  )
  return { data, ...restFn }
}

// deleteHook
export function useDeleteLostRequest({
  id,
  onSuccess,
}: {
  id: string
  onSuccess?: () => void
}) {
  const queryClient = useQueryClient()
  return useMutation(() => deleteLostRequest(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(LOST_REQUEST_KEYS.lists())
      onSuccess?.()
    },
  })
}
