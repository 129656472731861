import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Collapse, colors } from '@mui/material'

import Flex from 'src/atomics/Flex'
import Page from 'src/atomics/Page'
import KakaoMap from 'src/components/KakaoMap'
import LostCard from 'src/components/lost/LostCard'
import ToggleBar from 'src/components/ToggleBar'
import { useLostRequestList } from 'src/react-query-hooks/lost'

export default function LostListPage() {
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement>(null)

  const [isShowMap, setIsShowMap] = useState(true)

  const { data: lostRequestList } = useLostRequestList()

  const navigateToLostRequestDetail = (lostRequestDetailId: string) =>
    navigate(`/lost/${lostRequestDetailId}`, {
      state: { id: lostRequestDetailId },
    })

  // useEffect(() => {
  //   if (ref.current) {
  //     ref.current.scrollTop = 0
  //   }
  // }, [lostRequestList])

  return (
    <LostListPageContainer>
      <Flex flex direction={'column'}>
        <div className={'sticky__container'}>
          <Collapse in={isShowMap} timeout="auto">
            <KakaoMap search placeList={lostRequestList} />
          </Collapse>
          <ToggleBar
            value={isShowMap}
            onToggle={(value) => setIsShowMap(value)}
          />
        </div>
        <Flex style={{ padding: 16, fontSize: '1.3rem', fontWeight: 700 }}>
          실종동물 리스트
        </Flex>
        <Flex className={'LostCard__box'} direction={'column'} gap={12}>
          {lostRequestList.map((lostRequest) => (
            <LostCard
              key={lostRequest.id}
              lostInfo={lostRequest}
              isSelected={lostRequest.isSelected}
              onClick={() => navigateToLostRequestDetail(lostRequest.id!)}
            />
          ))}
        </Flex>
      </Flex>
    </LostListPageContainer>
  )
}

const LostListPageContainer = styled(Page)`
  .box {
    background: #f1f1f1;
    border-radius: 16px;
  }

  .title {
    color: ${colors.purple[700]};
    font-weight: 700;
  }

  .dogInfo {
    padding: 16px;
    margin-top: 16px;
  }

  .memo {
    overflow-y: auto;
    max-height: 200px;
  }

  .LostCard__box {
    margin: 16px;
  }

  .sticky__container {
    position: sticky;
    top: 60px;
    z-index: 10;
  }
`
