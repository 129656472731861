import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import RoomIcon from '@mui/icons-material/Room'

import Flex from 'src/atomics/Flex'
import Icon from 'src/atomics/Icon'
import useGeoLocation from 'src/hooks/useGeoLocation'

export default function ReportMenuBar({
  onAdd,
  onCancel,
  onConfirm,
}: {
  onAdd: () => void
  onCancel: () => void
  onConfirm: () => void
}) {
  const geocoder = new window.kakao.maps.services.Geocoder()
  const { pos, error } = useGeoLocation()
  const { lat, lng } = pos
  const [address, setAddress] = useState('')
  const [isOnAdd, setIsOnAdd] = useState(false)

  const handleClickAdd = () => {
    setIsOnAdd(true)
    onAdd()
  }

  const handleClickCancel = () => {
    setIsOnAdd(false)
    onCancel()
  }

  const handleClickConfirm = () => {
    console.log('fetch')
  }

  useEffect(() => {
    geocoder.coord2RegionCode(lng, lat, (result, status) => {
      if (status === window.kakao.maps.services.Status.OK) {
        setAddress(result[0].address_name)
      }
    })
  }, [lat, lng])

  return (
    <ReportMenuBarContainer align={'between'}>
      <Flex className={'menu__box'} gap={4}>
        <Flex className={'icon'} align={'centerY'}>
          <RoomIcon fontSize={'small'} style={{ color: '#666' }} />
        </Flex>
        <Flex className={'address'} align={'centerY'}>
          {address}
        </Flex>
      </Flex>
      <Flex style={{ gap: 12 }}>
        {/* {isOnAdd ? (
          <>
            <Flex onClick={handleClickCancel}>
              <Icon type={'CLEAR'} fontSize={'small'} color={'error'} />
            </Flex>
            <Flex onClick={handleClickConfirm}>
              <Icon type={'CHECK'} fontSize={'small'} color={'success'} />
            </Flex>
          </>
        ) : ( */}
        <Flex onClick={handleClickAdd}>
          <Icon
            type={'ADD_CIRCLE_OUTLINE'}
            fontSize={'small'}
            style={{ color: '#666' }}
          />
        </Flex>
        {/* )} */}
      </Flex>
    </ReportMenuBarContainer>
  )
}

const ReportMenuBarContainer = styled(Flex)`
  background: #fafafa;
  padding: 12px 16px;

  .menu__box {
    .icon {
    }
    .address {
      font-size: 12px;
      color: #666;
    }
  }
`
