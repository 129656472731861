/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMoveRequestInfo = /* GraphQL */ `
  query GetMoveRequestInfo($id: ID!) {
    getMoveRequestInfo(id: $id) {
      id
      moveInfo {
        requestType
        transportation
        tripType
        startPoint
        endPoint
        date
      }
      companionAnimalInfo {
        name
        age
        gender
        breed
        weight
        memo
        image
      }
      status
      createdAt
      updatedAt
    }
  }
`
export const listMoveRequestInfos = /* GraphQL */ `
  query ListMoveRequestInfos(
    $filter: ModelMoveRequestInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMoveRequestInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        moveInfo {
          requestType
          transportation
          tripType
          startPoint
          endPoint
          date
        }
        companionAnimalInfo {
          name
          age
          gender
          breed
          weight
          memo
          image
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getLostRequestInfo = /* GraphQL */ `
  query GetLostRequestInfo($id: ID!) {
    getLostRequestInfo(id: $id) {
      id
      lostInfo {
        lostDate
        lostPlace {
          address
          placeName
          location {
            lat
            lng
          }
        }
        contact
      }
      companionAnimalInfo {
        name
        age
        gender
        breed
        weight
        memo
        image
      }
      reportList {
        items {
          id
          image
          date
          place {
            address
            placeName
            location {
              lat
              lng
            }
          }
          createdAt
          updatedAt
          lostRequestInfoReportListId
        }
        nextToken
      }
      status
      createdAt
      updatedAt
    }
  }
`
export const listLostRequestInfos = /* GraphQL */ `
  query ListLostRequestInfos(
    $filter: ModelLostRequestInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLostRequestInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lostInfo {
          lostDate
          lostPlace {
            address
            placeName
            location {
              lat
              lng
            }
          }
          contact
        }
        companionAnimalInfo {
          name
          age
          gender
          breed
          weight
          memo
          image
        }
        reportList {
          items {
            id
            image
            date
            place {
              address
              placeName
            }
            createdAt
            updatedAt
            lostRequestInfoReportListId
          }
          nextToken
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getReportInfo = /* GraphQL */ `
  query GetReportInfo($id: ID!) {
    getReportInfo(id: $id) {
      id
      image
      date
      place {
        address
        placeName
        location {
          lat
          lng
        }
      }
      createdAt
      updatedAt
      lostRequestInfoReportListId
    }
  }
`
export const listReportInfos = /* GraphQL */ `
  query ListReportInfos(
    $filter: ModelReportInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        date
        place {
          address
          placeName
          location {
            lat
            lng
          }
        }
        createdAt
        updatedAt
        lostRequestInfoReportListId
      }
      nextToken
    }
  }
`
