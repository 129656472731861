import styled from 'styled-components/macro'

import { Divider, List, ListItemButton, ListItemText } from '@mui/material'

import Flex from 'src/atomics/Flex'
import Icon from 'src/atomics/Icon'

export default function Info({ onClose }: { onClose?: () => void }) {
  const handleClickClose = () => {
    onClose?.()
  }

  return (
    <InfoContainer>
      <Flex onClick={handleClickClose}>
        <Icon className={'icon__clear'} type={'CLEAR'} />
      </Flex>
      {/* <Divider /> */}
      <ListItemButton>
        <ListItemText primary={'내 정보'} />
      </ListItemButton>
      <Divider />
      <ListItemButton>
        <ListItemText primary={'신청한 봉사'} />
      </ListItemButton>
      <Divider />
      <ListItemButton>
        <ListItemText primary={'이동한 봉사'} />
      </ListItemButton>
      <Divider />
      <ListItemButton>
        <ListItemText primary={'로그아웃'} />
      </ListItemButton>
      <Divider />
    </InfoContainer>
  )
}

const InfoContainer = styled(List)`
  padding: 8px;

  .icon__clear {
    position: relative;
    left: 8px;
  }
`
