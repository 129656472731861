import { useState } from 'react'
import styled from 'styled-components/macro'

import { colors } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import Flex from '../atomics/Flex'
import Icon from '../atomics/Icon'

export default function IconTabs() {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <TabContainer>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="icon position tabs"
        sx={{ flex: 1 }}
      >
        <Tab
          icon={<Icon category={'requestType'} type={'DOMESTIC'} />}
          iconPosition="start"
          label="국내"
          sx={{ flex: 1 }}
        />
        <Tab
          icon={<Icon category={'requestType'} type={'ABROAD'} />}
          iconPosition="start"
          label="해외"
          sx={{ flex: 1 }}
        />
      </Tabs>
    </TabContainer>
  )
}

const TabContainer = styled(Flex)`
  button {
    max-width: unset;
    min-height: unset;
  }

  .MuiTabs-flexContainer {
    flex: 1;
  }
  .Mui-selected {
    color: ${colors.purple[700]};
  }
  .MuiTabs-indicator {
    background: ${colors.purple[700]};
  }
`
