import { useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Collapse, colors } from '@mui/material'
import { DropResult } from 'react-beautiful-dnd'

import KakaoMap from 'src/components/KakaoMap'
import LostDetailCard from 'src/components/lost/LostDetailCard'
import ReportDrawer from 'src/components/lost/ReportDrawer'
import ReportList from 'src/components/lost/ReportList'
import ReportMenuBar from 'src/components/lost/ReportMenuBar'
import ToggleBar from 'src/components/ToggleBar'
import { useLostRequestDetail } from 'src/react-query-hooks/lost'

import Flex from '../../atomics/Flex'
import Page from '../../atomics/Page'

export default function LostDetailPage() {
  const { id: lostRequestInfoID } = useParams()

  const [isShowMap, setIsShowMap] = useState(true)
  const [isOnReportAdd, setIsOnReportAdd] = useState(false)

  const { data: lostRequestDetail } = useLostRequestDetail(lostRequestInfoID!)
  const reportList = lostRequestDetail.reportList?.items ?? []

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return
    const items = Array.from(reportList)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
  }

  const handleReportAdd = () => setIsOnReportAdd(true)

  return (
    <LostDetailPageContainer>
      <Flex flex direction={'column'} gap={16}>
        <Flex
          style={{
            padding: 16,
            fontSize: '1.3rem',
            fontWeight: 700,
            color: 'red',
          }}
        >
          강아지를 찾습니다
        </Flex>

        <LostDetailCard
          lostRequestDetail={lostRequestDetail}
          onClick={() => {}}
        />

        <Flex direction={'column'}>
          <Collapse in={isShowMap} timeout="auto">
            <KakaoMap reportMarker reportList={reportList} />
          </Collapse>
          <ToggleBar
            value={isShowMap}
            onToggle={(value) => setIsShowMap(value)}
          />
        </Flex>
        <ReportMenuBar
          onAdd={handleReportAdd}
          onCancel={() => setIsOnReportAdd(false)}
          onConfirm={() => {}}
        />
        <ReportList
          onAdd={handleReportAdd}
          reportList={reportList}
          onDragEnd={handleDragEnd}
        />
        <ReportDrawer
          open={isOnReportAdd}
          reportList={reportList}
          onDragEnd={handleDragEnd}
          onClose={() => setIsOnReportAdd(false)}
        />
      </Flex>
    </LostDetailPageContainer>
  )
}

const LostDetailPageContainer = styled(Page)`
  .box {
    background: #f1f1f1;
    border-radius: 16px;
  }

  .title {
    color: ${colors.purple[700]};
    font-weight: 700;
  }

  .dogInfo {
    padding: 16px;
    margin-top: 16px;
  }

  .memo {
    overflow-y: auto;
    max-height: 200px;
  }
`
