import styled from 'styled-components/macro'

import Flex from '../atomics/Flex'

const CardStyle = styled(Flex)`
  border-radius: 12px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
`

export default CardStyle
