import { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'

import { Drawer } from '@mui/material'
import { OnDragEndResponder } from 'react-beautiful-dnd'
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil'

import ReportAddCard from './ReportAddCard'
import ReportList from './ReportList'
import Flex from 'src/atomics/Flex'
import {
  isOnEditAtom,
  isShowPositionMarkerAtom,
  placeSearchListAtom,
  selectedPlaceAtom,
} from 'src/stores/commonStores'

import KakaoMap, { IPlaceSearchResult } from '../KakaoMap'

export default function ReportDrawer({
  open,
  reportList,
  onAdd = () => {},
  onClose,
  onDragEnd = () => {},
}: {
  open: boolean
  reportList: Array<ReportInfo>
  onAdd?: () => void
  onClose?: () => void
  onDragEnd?: OnDragEndResponder
}) {
  const isOnEdit = useRecoilValue(isOnEditAtom)
  const ref = useRef<HTMLDivElement>(null)

  const [selectedPlace, setSelectedPlace] =
    useRecoilState<Partial<IPlaceSearchResult> | null>(selectedPlaceAtom)

  const setPlaceSearchList = useSetRecoilState(placeSearchListAtom)
  const resetIsShowPositionMarker = useResetRecoilState(
    isShowPositionMarkerAtom
  )
  const resetSelectedPlace = useResetRecoilState(selectedPlaceAtom)

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0
    }
  }, [selectedPlace])

  return (
    <Drawer
      anchor={'bottom'}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '10px 10px 0 0',
          height: 'calc(100vh - 120px)',
          minHeight: '60%',
        },
        ref,
      }}
    >
      <ReportAddDrawerContainer>
        <div className={'sticky__container'}>
          <Flex className={'title__box'} onClick={onClose}>
            제보 등록
          </Flex>
          <KakaoMap
            search
            positionMarker
            onSelect={(place) => setSelectedPlace(place)}
            reportMarker
            reportList={reportList}
          />
        </div>

        {!isOnEdit && selectedPlace && (
          <ReportAddCard
            placeInfo={selectedPlace}
            onConfirm={() => {}}
            onCancel={() => {
              resetSelectedPlace()
              resetIsShowPositionMarker()
              setPlaceSearchList((placeList) =>
                placeList.map((place) => ({ ...place, isSelected: false }))
              )
            }}
          />
        )}
        <ReportList
          onAdd={onAdd}
          reportList={reportList}
          onDragEnd={onDragEnd}
          showIcon
        />
      </ReportAddDrawerContainer>
    </Drawer>
  )
}

const ReportAddDrawerContainer = styled.div`
  position: relative;

  .sticky__container {
    position: sticky;
    top: 0;
    z-index: 20;

    .title__box {
      padding: 12px;
      font-size: 1.1rem;
      font-weight: 500;
      background: #fff;
    }
  }
`
