import { FC } from 'react'

import { MapMarker } from 'react-kakao-maps-sdk'

interface ICustomMarker {
  pos: PosType
  isSelected?: boolean
  isOnAdd?: boolean
  children?: React.ReactNode
  onClick?: () => void
}

const CustomMarker: FC<ICustomMarker> = ({
  pos,
  isSelected = false,
  isOnAdd = true,
  children,
  ...rest
}) => {
  const { lat, lng } = pos

  return (
    <MapMarker
      position={{ lat, lng }}
      image={
        isSelected
          ? {
              src: `/assets/svg/map_marker${isOnAdd ? '_p_add' : '_gr'}.svg`,
              size: { width: 50, height: 70 },
              options: { offset: { x: 25, y: 50 } },
            }
          : {
              src: '/assets/svg/map_marker_b.svg',
              size: { width: 50, height: 70 },
              options: { offset: { x: 25, y: 50 } },
            }
      }
      clickable
      {...rest}
    >
      {children}
    </MapMarker>
  )
}

export default CustomMarker
